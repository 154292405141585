import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {show} from './modal';

export const load = (overseerId, nodeId, lang, absenceId) => {
    ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <Form overseerId={overseerId} lang={lang} entryId={absenceId} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
}

function Form (props) {
    const [lang, updateLang] = useState(null);
    const [data, updateData] = useState(null);
    const [isStudentSupervisorAdmin, updateIsStudentSupervisorAdmin] = useState(null);
    const [periods, updatePeriods] = useState(null);
    const [chosenDate, updateChosenDate] = useState("");
    const [chosenPeriods, updateChosenPeriods] = useState([]);
    const [chosenReason, updateChosenReason] = useState(null);
    const [reasonDetails, updateReasonDetails] = useState("");
    const [buttonDisabled, updateButtonDisabled] = useState(false);
    const [editable, updateEditable] = useState(true);

    useEffect(() => {
        fetch("/js/" + props.lang + "/translation.js")
        .then(res => res.json())
        .then(res => {
            updateLang(res);
        });

        fetch("/" + props.lang + "/surveillance/absences/" + props.overseerId + "/fetch/" + (props.entryId == null ? "new" : props.entryId))
        .then(res => res.json())
        .then(async res => {
            updateData(res);
            updateEditable(res.editable);

            if (res.entry != null) {
                let tmpReason = res.reasons[0];

                res.reasons.forEach(r => {
                    if (r.id == res.entry.reason_id) {
                        tmpReason = r;
                    }
                })

                updateIsStudentSupervisorAdmin(res.isStudentSupervisorAdmin);
                updateChosenDate(res.entry.date_min);

                updateReason(tmpReason);
                updateReasonDetails(res.entry.note ?? "");
            }
        });
    }, [props.overseerId, props.lang, props.entryId]);

    useEffect(() => {
        if (chosenDate != null) {
            let day = new Date(chosenDate).getDay();

            if (day >= 0 && day <= 4) {
                fetch("/" + props.lang + "/surveillance/absences/" + props.overseerId + "/fetch-periods/" + chosenDate)
                .then(res => res.json())
                .then(res => {
                    updatePeriods(res);

                    if (data.entry != null && data.entry.id != null) {
                        let tmp = [];
                        res.forEach(r => {
                            if (data.entry.time_min <= r.time_min && data.entry.time_max >= r.time_max) {
                                tmp.push(r)
                            }
                        })
                        updateChosenPeriods(tmp);
                    }
                });
            }
            else {
                updatePeriods(null);
            }
        }
    }, [chosenDate])

    const loadPeriods = evt => {
        if (evt.target.value != null && evt.target.value != "") {
            updateChosenDate(evt.target.value);
            updateChosenPeriods([]);
            updateChosenReason(null);
        }
        else {
            updatePeriods(null);
        }
    }

    const selectPeriod = (evt, period) => {
        if (editable) {
            if (period == null) {
                let list = [];

                periods.forEach(p => {
                    if (p.absence_id == null || p.absence_id === props.entryId) {
                        list.push(p);
                    }
                })

                updateChosenPeriods(list);
            }
            else if (chosenPeriods.indexOf(period) === -1) {
                if (period.absence_id == null || period.absence_id == props.entryId) {
                    let tmpPeriods = [...chosenPeriods];
                    tmpPeriods.push(period);

                    updateChosenPeriods(tmpPeriods);
                }
            }
            else {
                let periods = [...chosenPeriods];
                periods.splice(chosenPeriods.indexOf(period), 1);
                updateChosenPeriods(periods);
            }

        }
    }

    const updateReason = reason => {
        if (editable) {
            if (chosenReason === null) {
                updateChosenReason(reason);

                if (!reason.need_more_info) {
                    updateReasonDetails("");
                }
            }
            else {
                updateChosenReason(null)
            }
        }
    }

    const storeAbsence = (periods, firstIteration = true) => {
        if (periods.length > 0) {
            const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            let formData = new FormData();
            formData.append("date", chosenDate);
            formData.append("time_min", periods[0].time_min);
            formData.append("time_max", periods[0].time_max);
            formData.append("reason_id", chosenReason.id);
            formData.append("reason_details", reasonDetails ?? "");

            let id = null;

            if (props.entryId != null && firstIteration) {
                id = props.entryId;
            }

            fetch("/" + props.lang + "/surveillance/absences/" + props.overseerId + "/" + id, {
                method : "POST",
                body : formData,
                headers: new Headers({
                    "X-CSRF-TOKEN": token,
                })
            })
            .then(res => res.json())
            .then(res => {
                periods.shift();

                if (periods.length === 0) {
                    if (isNaN(res)) {
                        show("Attention", res, null);
                        updateButtonDisabled(false);
                    }
                    else {
                        if (isStudentSupervisorAdmin) {
                            window.location.reload();
                        }
                        else {
                            window.location.href = "/" + props.lang + "/surveillance/absences/" + props.overseerId + "/new";
                        }
                    }
                }
                else {
                    storeAbsence(periods, false);
                }
            });
        }
    }

    const confirmDeclaration = evt => {
        evt.preventDefault();

        updateButtonDisabled(true);
        storeAbsence([...chosenPeriods]);

        return false;
    }

    let isBtnDisabled = buttonDisabled || chosenPeriods.length === 0 ||
                        (!isStudentSupervisorAdmin && (chosenReason != null && chosenReason.need_more_info && (reasonDetails == null || reasonDetails.trim().length == 0)));

    return <div className='container'>
                {
                    lang != null && data != null ?
                        <div>
                            <div className='row'>
                                <div className='col-sm-12 col-md-4 pt-1'>{lang.gen["Absence Date"]}</div>
                                <div className='col-sm-12 col-md-6'>
                                    <input type="date" disabled={!editable} min={data.dateMin} max={data.dateMax} className={'form-control ' + (chosenDate != "" ? "bg-success text-white" : "")} onChange={loadPeriods} value={chosenDate} />
                                </div>
                            </div>
                            {
                                periods != null && periods.length > 0 ?
                                    <form onSubmit={e => confirmDeclaration(e)}>
                                        <div className='row pt-3'>
                                            <div className='col-sm-12 col-md-4 pt-3'>{lang.gen["Choose Periods"]}</div>
                                            <div className='col-sm-12 col-md-6'>
                                                <div className={"card mt-2 mb-4 p-2 " + (periods.filter(p => p.absence_id == null || p.absence_id === props.entryId).length === chosenPeriods.length ? "d-none" : "")} key={chosenDate + "-all"} onClick={e => selectPeriod(e, null)}>
                                                    <span>{lang.gen["All Day"]}</span>
                                                </div>
                                                {
                                                    periods.map(p => {
                                                        let periodTitle = p.time_min_formatted + "-" + p.time_max_formatted + " : " + p.name;

                                                        return <div className={"card mt-2 mb-2 p-2 " + (isStudentSupervisorAdmin && props.entryId === "new" ? "d-flex flex-row " : "") +  (p.absence_id != null && p.absence_id != props.entryId ? "bg-secondary" : "") + " " + " " + (chosenPeriods.indexOf(p) !== -1 ? "bg-success text-white" : "")} key={chosenDate + "-" + p.id} onClick={e => selectPeriod(e, p)}>
                                                            <span>
                                                                {periodTitle}
                                                            </span>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>

                                        {
                                            chosenPeriods.length > 0 ?
                                                <div>
                                                    <div className='row pt-3'>
                                                        <div className='col-sm-12 col-md-4 pt-3'>{lang.gen["Absence Reason"]}</div>
                                                        <div className='col-sm-12 col-md-6'>
                                                            {
                                                                data.reasons.map(reason => {
                                                                    return <div key={reason.id} className={"card mt-2 mb-2 p-2 " + (chosenReason == null ? "" : chosenReason.id == reason.id ? "bg-success text-white" : "d-none")} onClick={e => updateReason(reason)}>
                                                                        {reason.name}
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }
                                        {
                                            chosenReason != null ?
                                                <div>
                                                    {
                                                        chosenReason.need_more_info ?
                                                            <div className='row pt-3'>
                                                                <div className='col-sm-12 col-md-4 pt-3'>{lang.gen["Need More Info Text"]}<br />{lang.gen["Required for Reason"]}</div>
                                                                <div className='col-sm-12 col-md-6'>
                                                                    <textarea className='form-control' required={!isStudentSupervisorAdmin} style={{minHeight : "100px"}} value={reasonDetails} onChange={e => updateReasonDetails(e.target.value)}></textarea>
                                                                </div>
                                                            </div>
                                                        :
                                                            null
                                                    }
                                                </div>
                                            :
                                                null
                                        }
                                        {
                                            chosenReason != null ?
                                                <div className=' pt-3'>
                                                    {
                                                        editable ?
                                                            <div className='mt-5 mb-5'>
                                                                <div className="row">
                                                                    <div className='col-sm-12 col-md-4 pt-1'></div>
                                                                    <div className='col-sm-12 col-md-6'>
                                                                        <button disabled={isBtnDisabled} className={'btn btn-primary ' + (!isBtnDisabled ? "" : "faded")} >{lang.gen["Complete Request"]}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        :
                                                            null
                                                    }
                                                </div>
                                            :
                                                null
                                        }
                                    </form>
                                :
                                    null
                            }
                        </div>
                    :
                        null
                }
          </div>
}