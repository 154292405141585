import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export const load = (lang, nodeId) => {
    ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <PredictionReport lang={lang} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
};

function PredictionReport (props) {
	const [lang, setLang] = useState(null);
	const [loading, setLoading] = useState(true);
	const [months, setMonths] = useState([]);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [chartData, setChartData] = useState(null);
	const [chartData2, setChartData2] = useState(null);
	const [daysChart, setDaysChart] = useState(null);
	const [daysChart2, setDaysChart2] = useState(null);

	useEffect(() => {
		fetch("/js/" + props.lang + "/translation.js")
		.then(res => res.json())
		.then(res => {
			setLang(res);
		});
	}, [props.lang]);

	useEffect(() => {
		if (lang != null) {
			fetch("/" + props.lang + "/reports/predictions/fetch")
			.then(res => res.json())
			.then(res => {
				let monthList = [];

				res.forEach(day => {
					let year = day.date.split("-")[0];
					let month = day.date.split("-")[1];
					let monthEntry = monthList.find(m => m.month * 1 == month * 1)

					if (!monthEntry) {
						monthEntry = {
							month: month,
							year: year,
							absences : 0,
							school_activities : 0,
							need : 0,
							predictions : 0,
							offset : 0,
							offsetPercent : 0,
							dates: []
						}

						monthList.push(monthEntry);
					}

					day.absences = day.periods.reduce((acc, period) => acc + period.absences, 0);
					day.school_activities = day.periods.reduce((acc, period) => acc + period.school_activities, 0);
					day.predictions = day.periods.reduce((acc, period) => acc + period.prediction, 0);
					day.need = day.periods.reduce((acc, period) => acc + period.total_need, 0);
					day.offset = day.absences - day.predictions;
					day.offsetPercent = Math.round(day.predictions != 0 ? (100.0 * day.offset/day.predictions) : 0);
					monthEntry.dates.push(day);
					monthEntry.absences += day.absences;
					monthEntry.school_activities += day.school_activities;
					monthEntry.predictions += day.predictions;
					monthEntry.need += day.need;

					monthEntry.offset += day.offset;
					monthEntry.offsetPercent += day.offsetPercent;
				});

				monthList.forEach(month => {
					month.offsetPercent = Math.round(month.offsetPercent / month.dates.length);
				});

				setChartData({
					labels: monthList.map(m => lang.gen["Month_" + m.month] + " " + m.year),
					datasets: [
						{
							label: lang.gen["Declared absences"],
							data: monthList.map(m => m.absences),
							fill: false,
							backgroundColor: 'rgb(150, 150, 150)',
							borderColor: 'rgba(150, 150, 150, 1)',
						},
						{
							label: lang.gen["Replacement prediction"],
							data: monthList.map(m => m.predictions),
							fill: false,
							backgroundColor: 'rgb(171, 211, 171)',
							borderColor: 'rgba(171, 211, 171, 1)',
						},
					]
				});

				setChartData2({
					labels: monthList.map(m => lang.gen["Month_" + m.month] + " " + m.year),
					datasets: [
						{
							label: lang.gen["Offset_Percent"],
							data: monthList.map(m => m.offsetPercent),
							fill: false,
							backgroundColor: 'rgb(150, 150, 150)',
							borderColor: 'rgba(150, 150, 150, 1)',
						},
					]
				});

				let days = {};
				let days2 = {};

				monthList.forEach(month => {
					days[month.month + ""] = [];
					days2[month.month + ""] = [];

					month.dates.forEach(day => {
						days[month.month + ""].push({
							label : day.date,
							absences : day.absences,
							predictions : day.predictions,
						});
						days2[month.month + ""].push({
							label : day.date,
							offsetPercent : day.offsetPercent,
						});
					});
				});

				let finalDays = {};
				let finalDays2 = {};

				Object.keys(days).forEach(key => {
					finalDays[key] = {
						labels: days[key].map(d => d.label),
						datasets: [
							{
								label: lang.gen["Declared absences"],
								data: days[key].map(d => d.absences),
								fill: false,
								backgroundColor: 'rgb(150, 150, 150)',
								borderColor: 'rgba(150, 150, 150, 1)',
							},
							{
								label: lang.gen["Replacement prediction"],
								data: days[key].map(d => d.predictions),
								fill: false,
								backgroundColor: 'rgb(171, 211, 171)',
								borderColor: 'rgba(171, 211, 171, 1)',
							},
						]
					};

					finalDays2[key] = {
						labels: days2[key].map(d => d.label),
						datasets: [
							{
								label: lang.gen["Offset_Percent"],
								data: days2[key].map(d => d.offsetPercent),
								fill: false,
								backgroundColor: 'rgb(150, 150, 150)',
								borderColor: 'rgba(150, 150, 150, 1)',
							},
						]
					};
				});

				setDaysChart(finalDays);
				setDaysChart2(finalDays2);
				setMonths(monthList)
				setLoading(false);
			});
		}
	}, [lang])

	return 	<div>
				{
					!loading ?
						<div>
							<div className='d-flex justify-content-between mb-5'>
								<div style={{flexBasis: '45%'}}>
									<Line options={{
											responsive: true,
											plugins: {
											legend: {
												position: 'top',
											},
											title: {
												display: true,
												text: lang.gen["Declared absences"] + " VS " + lang.gen["Replacement prediction"],
											},
											},
										}}
										data={selectedMonth == null ? chartData : daysChart[selectedMonth + ""]} />
								</div>
								<div style={{flexBasis: '45%'}}>
									<Line options={{
											responsive: true,
											plugins: {
											legend: {
												position: 'top',
											},
											title: {
												display: true,
												text: lang.gen["Offset_Percent"],
											},
											},
										}}
										data={selectedMonth == null ? chartData2 : daysChart2[selectedMonth + ""]} />
								</div>
							</div>
							<table className='table text-sm'>
								<thead>
									<tr>
										<th>{lang.gen["Month"]}</th>
										<th className='text-center' style={{ backgroundColor : "#ddd" }}>{lang.gen["Declared absences excluding school activities"]}</th>
										<th className='text-center bg-light-green'>{lang.gen["Replacement prediction"]}</th>
										<th className='text-center'>{lang.gen["Offset"]}</th>
										<th className='text-center'>{lang.gen["Offset_Percent"]}</th>
									</tr>
								</thead>
								<tbody>
									{
										months.map(month => {
											return <React.Fragment key={month.month}>
														<tr  className={'cursor-pointer fade-hover ' + (selectedMonth === month.month ? "fw-bold" : "")} onClick={() => selectedMonth === month.month ? setSelectedMonth(null)  : setSelectedMonth(month.month)}>
															<td>{lang.gen["Month_" + month.month]} {month.year}</td>
															<td className='text-center' style={{ backgroundColor : "#ddd" }}>{month.absences}</td>
															<td className='text-center bg-light-green'>{month.predictions}</td>
															<td className='text-center'>{month.offset}</td>
															<td className='text-center'>{month.offsetPercent} %</td>
														</tr>
														{
															selectedMonth === month.month ?
																month.dates.map(day => {
																	return 	<tr key={day.date} className='text-xs'>
																				<td>{day.date}</td>
																				<td className='text-center' style={{ backgroundColor : "#ddd" }}>{day.absences}</td>
																				<td className='text-center bg-light-green'>{day.predictions}</td>
																				<td className='text-center'>{day.offset}</td>
																				<td className='text-center'>{day.offsetPercent} %</td>
																			</tr>
																})
															:
																null
														}
													</React.Fragment>
										})
									}
									<tr className='fw-bold'>
										<td className=''>{lang.gen["Total"]}</td>
										<td className='text-center' style={{ backgroundColor : "#ddd" }}>{months.reduce((acc, month) => acc + month.absences, 0)}</td>
										<td className='text-center bg-light-green'>{months.reduce((acc, month) => acc + month.predictions, 0)}</td>
										<td className='text-center'>{months.reduce((acc, month) => acc + month.offset, 0)}</td>
										<td className='text-center'>{parseInt((months.reduce((acc, month) => acc + month.offsetPercent, 0))/months.length)} %</td>
									</tr>
								</tbody>
							</table>
						</div>
					:
					<>
						<div className='mt-4 d-flex justify-content-center items-center'>
							<div className="spinner-border" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
						{
							lang != null ?
								<div className='mt-4 d-flex justify-content-center items-center'>
									<p>
										{lang.gen["Please Wait 2"]}
									</p>
								</div>
							:
								null
						}
					</>

				}
			</div>
}6