import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export const load = (lang, nodeId, url, fieldName, draggable, clickedColor = null) => {
    ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <Affinities url={url} draggable={draggable ?? true} fieldName={fieldName} lang={lang} clickedColor={clickedColor} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
}

function Affinities (props) {
    const [lang, updateLang] = useState(null);
    const [choices, updateChoices] = useState(null);
	const [items, updateItems] = useState(null);
	const [selectValue, updateSelectValue] = useState("");
	const dragItem = useRef();
	const dragOverItem = useRef();

	const fetchData = () => {
		fetch("/js/" + props.lang + "/translation.js")
		.then(res => res.json())
		.then(res => {
			updateLang(res);

			fetch(props.url)
			.then(res => res.json())
			.then(res => {
				updateItems(res.items);
				updateChoices(res.choices);
			})
		});
	}

    useEffect(() => {
		fetchData();
	}, []);

	const addItem = choiceId => {
		if (choiceId != "") {
			let choice = choices.find(u => u.id == choiceId);
			let itemList = [...items, choice];
			updateItems(itemList);
		}

		updateSelectValue("");
	}

	const removeItem = (e, affinity) => {
		e.stopPropagation();
		let filtered = items.filter(u => u.id !== affinity.id);
		let itemList = [...filtered];
		updateItems(itemList);
	}

	const dragStart = (e, position) => {
		dragItem.current = position;
	};

	const dragEnter = (e, position) => {
		dragOverItem.current = position;
	};

	const drop = e => {
		const copyListItems = [...items];
		const dragItemContent = copyListItems[dragItem.current];
		copyListItems.splice(dragItem.current, 1);
		copyListItems.splice(dragOverItem.current, 0, dragItemContent);
		dragItem.current = null;
		dragOverItem.current = null;
		updateItems(copyListItems);
	};

	const setClicked = item => {
		item.selected = !item.selected;
		const copyListItems = [...items];
		updateItems(copyListItems);
	}


	return <div>
				{
					lang != null && choices != null ?
						<div>
							<div onDragOver={e => e.preventDefault()}>
								{
									items.map((item, index) => {
										let style = {};

										if (item.location_color != null && item.location_color !== "") {
											style["backgroundColor"] = item.location_color;
										}

										return  <div key={item.id}
													className={"border rounded mb-1 p-1 d-flex justify-content-between " + (props.draggable ? "cursor-move" : "")}
													style={style}
													onClick={e => props.clickedColor != null ? setClicked(item) : null}
													onDragStart={(e) => dragStart(e, index)}
													onDragEnter={(e) => dragEnter(e, index)}
													onDragEnd={drop}
													draggable={props.draggable}>
													<div>
														<input type="hidden" name={props.fieldName + "[]"} value={item.id} />
														<input type="hidden" name={props.fieldName + "Selected[]"} value={item.selected ? "1" : "0"} />
														{
															(item.selected && props.clickedColor != null) ?
																<span className='me-1 fa fa-triangle-exclamation text-danger'></span>
															:
																null
														}
														{
															((item.id + "").indexOf("g") === 0) ?
																<span className='me-1 fa fa-sitemap'></span>
															:
																null
														}

														{item.label}
													</div>
													<div>
														<button type="button" onClick={e => removeItem(e, item)} className='btn btn-sm btn-outline-danger'>X</button>
													</div>
												</div>
									})
								}
							</div>
							<div className="mt-2">
								<select className="form-select" value={selectValue} onChange={e => addItem(e.target.value)}>
									<option value="">--</option>
									{
										choices.map(choice => {
											let item = items.find(aff => choice.id == aff.id);

											if (item == null) {
												items.forEach(temp => {
													if (item == null && temp.children != null) {
														item = temp.children.find(child => choice.id == child.id);
													}
												});
											}

											if (item == null) {
												if (choice.children != null) {
													choice.children.forEach(child => {
														items.forEach(el => {
															if (item == null) {
																if (el.id == child.id) {
																	item = el;
																}
																else if (el.children != null) {
																	item = el.children.find(elChild => choice.id == elChild.id);
																}
															}
														})
													});
												}
											}

											return item != null ? null : <option key={choice.id} value={choice.id}>
												{choice.label}
											</option>
										})
									}
								</select>
							</div>
						</div>
					:
						null
				}
		</div>
}