import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export const load = (lang, nodeId, selectList, fieldId, type) => {
    ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <SelectList lang={lang} selectList={selectList} type={type} fieldId={fieldId} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
}

function SelectList (props) {
    const [lang, updateLang] = useState(null);
    const [items, updateItems] = useState([]);
    const [selectedItems, updateSelectedItems] = useState(props.selectList ?? []);

    useEffect(() => {
        fetch("/js/" + props.lang + "/translation.js")
        .then(res => res.json())
        .then(res => {
            updateLang(res);
        });

        fetch("/" + props.lang + "/" + props.type + "s/asJson/all")
        .then(res => res.json())
        .then(res => {
            updateItems(res);
        });
    }, [props.lang]);

    const addItem = (id) => {
        let list = [id, ...selectedItems];
        updateSelectedItems(list);
    }

    const removeItem = (id) => {
        let list = [...selectedItems];

        if (list.indexOf(id) > -1) {
            list.splice(list.indexOf(id), 1);
        }

        updateSelectedItems(list);
    }

    const formatText = item => {
        let txt = "";

        if (props.type == "course") {
            txt = "(" + item.code + ")" + item.name;
        }
        else {
            txt = item.last_name + ", " + item.first_name;
        }

        return txt;
    }

    return <div className='d-flex text-sm justify-content-between'>
                {
                    items.length > 0 ?
                        <>
                            <div style={{flexBasis : "49%"}}>
                                <h5>{lang.gen["List"]}</h5>
                                <div className='border ps-1' style={{height:400, overflowY : "scroll"}}>
                                    {
                                        items.filter(c => selectedItems.indexOf(c.id) === -1 && (props.type != "course" || (c.expertise_field_id == null || c.expertise_field_id === props.fieldId))).map(item => {
                                            return <div key={"item-" + item.id} className={'list-selected-line ' + (item.status != null && item.status === "inactive" ? "faded" : "")} onClick={() => addItem(item.id)}>
                                                {formatText(item)}
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{flexBasis : "49%"}}>
                                <h5>{lang.gen["Added"]}</h5>
                                <div className='border ps-1' style={{height:400, overflowY : "scroll"}}>
                                    {
                                        selectedItems.map(sc => {
                                            let item = items.find(c => c.id == sc);

                                            if (item != null) {
                                                return <div key={"sel-item-" + item.id} className={'list-selected-line ' + (item.status != null && item.status === "inactive" ? "faded" : "")} onClick={() => removeItem(item.id)}>
                                                            <input type="hidden" name={props.type + "_selected_ids[]"} value={item.id} />
                                                            {formatText(item)}
                                                        </div>
                                            }
                                            else {
                                                return null;
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </>
                    :
                        null
                }
          </div>
}