import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import frLocale from '@fullcalendar/core/locales/fr';
import {show} from './modal';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export const load = (teacherId, lang, nodeId, isTeacherAdmin = false) => {
    ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <Calendar teacherId={teacherId} lang={lang} isTeacherAdmin={isTeacherAdmin} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
}

function Calendar (props) {
	const [events, updateEvents] = useState([]);
    const [lang, updateLang] = useState(null);
	const [minDate, updateMinDate] = useState(null);
	const [maxDate, updateMaxDate] = useState(null);
	const [periodFilter, updatePeriodFilter] = useState("all");

	const fetchData = () => {
		fetch("/js/" + props.lang + "/translation.js")
		.then(res => res.json())
		.then(res => {
			updateLang(res);

			fetch("/" + props.lang + "/users/" + props.teacherId + "/calendar")
			.then(res => res.json())
			.then(res => {
				let list = [];
				res.events.forEach(e => {
					let add = false;

					if (periodFilter == "all") add = true;
					else if (periodFilter == "absences" && e.absence_id != null && !e.is_substitute)  add = true;
					else if (periodFilter == "substitutions" && e.is_substitute)  add = true;

					if (add) {
						let bgColor = e.is_substitute ? "#fd7e14" : e.absence_id != null ? "#0dcaf0" : e.type > 0 ? '#0d6efd' : '#777';

						if (e.is_available_period) {
							bgColor = "purple";
						}

						let currentDate = new Date(e.date);
						list.push({
							"id" : currentDate.toISOString(),
							"num" : e.type,
							"title": e.title,
							"start": currentDate.toISOString(),
							"end": currentDate.toISOString(),
							"allDay": true,
							"classNames" : !e.is_past ? null : ["faded"],
							"backgroundColor": bgColor,
							"extendedProps": {
								"absence_id" : e.absence_id,
								"period" : e.period,
								"editable" : e.editable,
								"isSubstitute" : e.is_substitute,
							},
						})
					}
				});

				updateEvents(list);
				updateMinDate(res.dateMin);
				updateMaxDate(res.dateMax);
			})
		});
	}

	useEffect(() => {
		fetchData();
	}, [props.lang, props.teacherId, periodFilter]);

	const quickAdd = (date, period, formURL) => {
		fetch("/" + lang + "/admin/list/teachers")
		.then(res => res.json())
		.then(teachers => {
			fetch("/" + lang + "/reasons/list/json")
			.then(res => res.json())
			.then(reasons => {
				let div = document.createElement("div");
				let p1 = document.createElement("p");
				p1.innerHTML = lang.gen["Choose Substitute And Reason"];
				let p2 = document.createElement("p");
				p2.className = "mt-5 text-sm";
				let a = document.createElement("a");
				a.href = formURL;
				a.innerHTML = lang.gen["Choose Substitute And Reason 2"];
				p2.append(a);
				div.append(p1);
				div.append(p2);

				let select = document.createElement("select");
				select.classList.add("form-select", "mt-2");

				teachers.forEach(teacher => {
					let option = document.createElement("option");
					option.value = teacher.id
					option.innerText = teacher.last_name + ", " + teacher.first_name;
					select.append(option);
				})

				p1.append(select);

				let selectReason = document.createElement("select");
				selectReason.classList.add("form-select", "mt-2");

				reasons.forEach(reason => {
					let option = document.createElement("option");
					option.value = reason.id
					option.innerText = lang == "fr" ? reason.name_fr : reason.name_en;
					selectReason.append(option);
				})

				p1.append(selectReason);

				show(lang.gen["Attention"],div, modalView => {
					let formData = new FormData();
					formData.append("date", date);
					formData.append("period", period);
					formData.append("reason_id", selectReason.value);
					formData.append("needs_substitute", "1");
					formData.append("substitute_text", "");
					formData.append("substitute_url", "");
					formData.append("substitute_id", select.value);

					fetch("/" + props.lang + "/absences/" + props.teacherId + "/new", {
						method : "POST",
						body : formData,
						headers: new Headers({
							"X-CSRF-TOKEN": token,
						})
					})
					.then(res => res.json())
					.then(res => {
						modalView.hide();
						fetchData();
					});
				}, lang.gen["Save Absence"]);
			});
		});
	}

	return <div>
				{
					lang != null ?
						<React.Fragment>
							{
								props.isTeacherAdmin ?
									<div className='mb-3 row'>
										<div className='col-3'>
											<select className='form-select' value={periodFilter} onChange={e => updatePeriodFilter(e.target.value)}>
												<option value="all">{lang.gen["Calendar Show All"]}</option>
												<option value="absences">{lang.gen["Calendar Show Absences"]}</option>
												<option value="substitutions">{lang.gen["Calendar Show Substitutions"]}</option>
											</select>
										</div>
									</div>
								:
									null
							}

							<FullCalendar
								plugins={[ dayGridPlugin, bootstrap5Plugin  ]}
								locales={[frLocale]}
								firstDay={0}
								events={events}
								locale={props.lang}
								weekends={false}
								initialView="dayGridMonth"
								themeSystem= 'bootstrap5'
								timeZone='America/Montreal'
								validRange={{
									start : minDate,
									end : maxDate
								}}
								eventClick={info => {
									if (info.event.extendedProps.isSubstitute) {
										window.location.href="/" + props.lang + "/absences/" + props.teacherId + "/substitutions/" + info.event.extendedProps.absence_id;
									}
									else if (info.event.extendedProps.editable) {
										if (info.event.extendedProps.absence_id != null) {
											window.location.href="/" + props.lang + "/absences/" + props.teacherId + "/" + info.event.extendedProps.absence_id;
										}
										else {
											let p = info.event.extendedProps.period;
											let d = info.event.start.toISOString().split('T')[0];
											let url = "/" + props.lang + "/absences/" + props.teacherId + "/" + d + "-" + p;;

											if (props.isTeacherAdmin) {
												quickAdd(d, p, url);
											}
											else {
												window.location.href=url;
											}
										}
									}
								}}
							/>
						</React.Fragment>
					:
						null
				}
		</div>
}