import React from 'react';

export default function ModalComp(props) {

    return (
            <div className="modal d-block show bg-semi-opaque" tabIndex="-1">
                <div className="modal-dialog" style={{minWidth : props.width ?? null}}>
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>
                    </div>
                    <div className="modal-body">
                        {props.children}
                    </div>
                    <div className="modal-footer justify-content-between">
                        {
                            props.closeAction != null ?
                                <button type="button" onClick={props.closeAction} className="btn btn-secondary">{props.closeText ?? props.lang.gen["Close"]}</button>
                            :
                                null
                        }
                        {
                            props.deleteAction != null ?
                                <button type="button" onClick={props.deleteAction} className="btn btn-outline-danger">{props.lang.gen["Delete"]}</button>
                            :
                                null
                        }
                        {
                            props.otherAction != null ?
                                <button type="button" onClick={props.otherAction} className="btn btn-outline-primary">{props.otherActionTxt}</button>
                            :
                                null
                        }
                        {
                            props.confirmAction != null || props.confirmIsSubmit ?
                                <button type={props.confirmIsSubmit ? "submit" : "button"} onClick={props.confirmAction ?? null} className="btn btn-primary">{props.lang.gen["Confirm"]}</button>
                            :
                                null
                        }
                    </div>
                    </div>
                </div>
            </div>
    );
}
