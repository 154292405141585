import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from '@fullcalendar/interaction';
import {show} from './modal';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import frLocale from '@fullcalendar/core/locales/fr';

export const load = (lang, nodeId) => {
    ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <Calendar lang={lang} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
}


function Calendar (props) {
	const [events, updateEvents] = useState([]);
	const [minDate, updateMinDate] = useState(null);
	const [maxDate, updateMaxDate] = useState(null);
    const [lang, updateLang] = useState(null);

	useEffect(() => {
		fetch("/js/" + props.lang + "/translation.js")
		.then(res => res.json())
		.then(res => {
			updateLang(res);
			let tmpLang = res;

			fetch("/" + props.lang + "/admin/calendar/fetch")
			.then(res => res.json())
			.then(res => {
				let list = [];

				res.events.forEach(e => {
					let currentDate = new Date(e.date);

					list.push({
						"id" : currentDate.toISOString(),
						"num" : e.type,
						"title": e.type > 0 ? e.type : e.type == 0 ? tmpLang.gen["Pedagogical Day"] : tmpLang.gen["Free Day"],
						"start": currentDate.toISOString(),
						"end": currentDate.toISOString(),
						"allDay": true,
						"backgroundColor": e.type > 0 ? '#0d6efd' : '#777',
					})
				});

				updateEvents(list);
				updateMinDate(res.dateMin);
				updateMaxDate(res.dateMax);
			})
		});
	}, [props.lang]);

	const clearEvents = () => {
		updateEvents([]);
	}

	const saveEvents = e => {
		const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

		e.target.setAttribute("disabled", "disabled");

		let formData = new FormData();
		formData.append("events", JSON.stringify(events));

		fetch("/" + props.lang + "/admin/calendar/store", {
			method : "POST",
			body : formData,
			headers: new Headers({
                "X-CSRF-TOKEN": token
            })
		})
		.then(res => res.json())
		.then(res => {
			e.target.removeAttribute("disabled");

			show(lang.gen["Attention"], lang.gen["Calendar Saved"]);
		});
	}

	return <div>
				{
					lang != null ?
						<div>
							<FullCalendar
								plugins={[ dayGridPlugin, bootstrap5Plugin, interactionPlugin   ]}
								locales={[frLocale]}
								locale={props.lang}
								firstDay={0}
								initialView="dayGridMonth"
								themeSystem= 'bootstrap5'
								events={events}
								weekends={false}
								timeZone='America/Montreal'
								validRange={{
									start : minDate,
									end : maxDate
								}}
								dateClick={info => {
									let firstDay = true;
									let currentDate = new Date(info.dateStr);

									if (currentDate.getDay() < 5) {
										let div = document.createElement("div");
										let form = document.createElement("form");
										form.classList.add("choice-form");
										div.append(form);

										for (let i = 1; i <= 12; i++) {
											let lab1 = document.createElement("label");
											lab1.classList.add("form-control", "form-control-sm", "mb-2", "text-sm");
											let rad1 = document.createElement("input");
											rad1.classList.add("me-2");
											rad1.type = "radio";
											rad1.name = "choice";

											if (i <= 9) {
												rad1.value = i;
												lab1.append(rad1, lang.gen["Day"] + " " + i);
											}
											else if (i == 10) {
												rad1.value = 0;
												lab1.append(rad1, lang.gen["Pedagogical Day"]);
											}
											else if (i == 11) {
												rad1.value = -1;
												lab1.append(rad1, lang.gen["Free Day"]);
											}
											else if (i == 12) {
												rad1.value = -2;
												lab1.append(rad1, lang.gen["Empty"]);
											}

											if (i == 1) {
												rad1.setAttribute("checked", "checked");
											}

											form.append(lab1);
										}

										let cascadeDiv = document.createElement("div");
										let cascadeLabel = document.createElement("label");
										let cascadeCheck = document.createElement("input");
										cascadeCheck.type = "checkbox";
										cascadeCheck.classList.add("me-2");
										cascadeCheck.name = "cascadeChoice";
										cascadeLabel.classList.add("form-control", "form-control-sm", "mt-4", "text-sm");
										cascadeLabel.append(cascadeCheck, lang.gen["Cascade"]);
										cascadeDiv.append(cascadeLabel);
										div.append(cascadeDiv);

										show(info.dateStr, div, modalView => {
											modalView.hide();
											let num = document.querySelector(".choice-form").choice.value;

											if (num != null && num != "" && !isNaN(num)) {
												let tmp = [...events];
												num = parseInt(num);

												while (currentDate < new Date(maxDate)) {
													if (currentDate.getDay() < 5) {
														let foundDate = null;

														events.forEach(e => {
															if (e.id == currentDate.toISOString()) {
																foundDate = e;
															}
														})

														if (num <= 0) {
															let tmpNum = num;

															if (foundDate != null) {
																if (tmpNum == -2) {
																	num = parseInt(foundDate.title);
																	tmp.splice(tmp.indexOf(foundDate), 1);
																}
																else {
																	num = parseInt(foundDate.title);
																	foundDate.num = tmpNum;
																	foundDate.title = tmpNum == 0 ? lang.gen["Pedagogical Day"] : lang.gen["Free Day"];
																	foundDate.backgroundColor = '#777';
																}

																if (isNaN(num)) {
																	break;
																}
															}
															else {
																setTimeout(() => {
																	show("Attention", lang.gen["Please Proceed Left To Right"], null);
																}, 250);
																break;
															}
														}
														else {
															let incNum = true;

															if (foundDate != null) {
																if (!isNaN(foundDate.title)) {
																	foundDate.title = num;
																	foundDate.num = num;
																	foundDate.backgroundColor = '#0d6efd';
																}
																else {
																	if (firstDay) {
																		foundDate.num = num;
																		foundDate.title = num
																		foundDate.backgroundColor = '#0d6efd';
																	}
																	else {
																		incNum = false;
																	}
																}
															}
															else {
																let hasDateAfter = false;

																events.forEach(e => {
																	if (new Date(e.id) > currentDate) {
																		hasDateAfter = true;
																	}
																})

																if (hasDateAfter && !firstDay) {
																	incNum = false;
																}
																else {
																	let evt = {
																		"id" : currentDate.toISOString(),
																		"num" : num,
																		"title": num,
																		"start": currentDate.toISOString(),
																		"end": currentDate.toISOString(),
																		"allDay": true,
																		"backgroundColor": '#0d6efd',
																	}

																	tmp.push(evt);
																}
															}

															if (incNum) {
																num = num + 1 == 10 ? 1 : num + 1;
															}
														}
													}

													currentDate = new Date(currentDate.getTime() + 86400000);
													firstDay = false;

													if (!cascadeCheck.checked) {
														break;
													}
												}

												updateEvents(tmp);
											}
										});
									}
								}}
							/>

							<div className='mt-3 d-flex justify-content-between'>
								<button className='btn btn-danger' onClick={e => clearEvents(e)}>
									{lang.gen["Empty Calendar"]}
								</button>

								<button className='btn btn-primary' onClick={e => saveEvents(e)}>
									{lang.gen["Save"]}
								</button>
							</div>
						</div>
					:
					null
				}
		</div>
}