import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import YearCalendarMonth from './year-calendar-month';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ModalComp from './modal-comp';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export const load = (lang, nodeId, year, versionId) => {
    ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <YearCalendar lang={lang} year={year} versionId={versionId} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
}

function YearCalendar (props) {
    const [lang, updateLang] = useState(null);
	const [events, updateEvents] = useState(null);
	const [days, updateDays] = useState(null);
	const [months, updateMonths] = useState(null);
	const [selectedDay, updateSelectedDay] = useState(null);
	const [selectedDays, updateSelectedDays] = useState(null);
	const [showModalTriggerSchoolDays, updateShowModalTriggerSchoolDays] = useState(false);
	const [schoolDayCascade, updateSchoolDayCascade] = useState(1);
	const [showPleaseWait, updateShowPleaseWait] = useState(false);
	const [showClearSchoolNumber, updateShowClearSchoolNumber] = useState(false);

	const refreshData = () => {
		fetch("/" + props.lang + "/admin/year-calendar/" + props.year + "/" + props.versionId + "/fetch")
		.then(res => res.json())
		.then(res => {

			let months = [];
			let years = props.year.split("-");
			years.forEach((year, yearIdx) => {
				for(let m = 1; m <= 6; m++) {
					let monthNo = m + (yearIdx === 0 ? 6 : 0);
					monthNo = monthNo < 10 ? "0" + monthNo : monthNo;

					months.push({
						"short": year + "-" + monthNo,
						"name" : new Date(year + "-" + monthNo + "-10").toLocaleString("fr", {month : "long"}) + " | " + new Date(year + "-" + monthNo + "-10").toLocaleString("en", {month : "long"}),
						"year" : year,
						"month" : monthNo,
					});
				}
			})

			months.sort((a, b) => a.short < b.short ? -1 : 1);

			updateEvents(res.events);
			updateDays(res.days);
			updateMonths(months);
		});
	}

	useEffect(() => {
		fetch("/js/" + props.lang + "/translation.js")
		.then(res => res.json())
		.then(res => {
			updateLang(res);
			refreshData();
		});
	}, [props.lang]);

	const dayClicked = (year, month, day) => {
		let dayString = year + "-" + month + "-" + (day < 10 ? "0" + day : day);

		let dayEntry = days.find(d => d.date == dayString);
		updateSelectedDay({...dayEntry});
	}

	const intervalSelected =  (year, month, day_start, day_end) => {
		updateSelectedDays({year, month, day_start, day_end, has_student_presence : true, has_teacher_presence : true})
	}

	const confirmUpdate = (e) => {
		e.preventDefault();
		updateShowPleaseWait(true);

		let formData = new FormData();

		if (selectedDay.school_day != null && selectedDay.school_day > 0)  {
			formData.append("school_day", selectedDay.school_day);
		}

		formData.append("trigger_cascade", selectedDay.trigger_cascade ? 1 : 0);
		formData.append("has_student_presence", selectedDay.has_student_presence ? 1 : 0);
		formData.append("has_teacher_presence", selectedDay.has_teacher_presence ? 1 : 0);
		formData.append("events", JSON.stringify(selectedDay.events));

		fetch("/" + props.lang + "/admin/year-calendar/" + props.year + "/" + props.versionId + "/store/" + selectedDay.id, {
			method : "POST",
			body : formData,
			headers: new Headers({
				"X-CSRF-TOKEN": token,
			})
		})
		.then(res => res.json())
		.then(res => {
			updateSelectedDay(null);
			updateShowModalTriggerSchoolDays(false);
			updateShowPleaseWait(false);
			refreshData();
		});
	}

	const confirmTrigger = e => {
		e.preventDefault();
		selectedDay.trigger_cascade = true;
		confirmUpdate(e);
	}

	const triggerSchoolDays = () => {
		updateSchoolDayCascade(selectedDay.school_day);
		updateShowModalTriggerSchoolDays(true);
	}

	const updateSchoolDay = (day) => {
		selectedDay.school_day = day ?? null;
		updateSelectedDay({...selectedDay});
	}

	const togglePresence = (name, value) => {
		let tmp = {...selectedDay};
		tmp[name] = value;

		updateSelectedDay(tmp);
	}

	const addRemoveDayEvents = (eventId, checked) => {
		let events = [...selectedDay.events];

		if (checked) {
			events.push(eventId);
		}
		else {
			events.splice(selectedDay.events.indexOf(eventId), 1);
		}

		selectedDay.events = events;

		if (!canHaveSchoolDay(selectedDay)) {
			selectedDay.school_day = null;
		}

		updateSelectedDay({...selectedDay});
	}

	const canHaveSchoolDay = (entry) => {
		let can = true;

		events.forEach(evt => {
			entry.events.forEach(eId => {
				if (evt.id == eId) {
					if (evt.is_not_a_school_day) {
						can = false;
					}
				}
			})
		})

		return can;
	}

	const confirmClearNumber = (e) => {
		e.preventDefault();
		updateShowPleaseWait(true);

		fetch("/" + props.lang + "/admin/year-calendar/" + props.year + "/" + props.versionId + "/clear-numbers", {
			method : "POST",
			headers: new Headers({
				"X-CSRF-TOKEN": token,
			})
		})
		.then(res => res.json())
		.then(res => {
			updateShowPleaseWait(false);
			updateShowClearSchoolNumber(false);
			refreshData();
		});
	}

	const updateSelectionDaysParam = (name, value) => {
		let selDays = {...selectedDays};
		selDays[name] = value;
		updateSelectedDays(selDays);
	}

	const confirmDaysUpdate = (e) => {
		e.preventDefault();
		updateShowPleaseWait(true);

		let formData = new FormData();
		formData.append("year", selectedDays.year);
		formData.append("month", selectedDays.month);
		formData.append("day_start", selectedDays.day_start);
		formData.append("day_end", selectedDays.day_end);
		formData.append("has_student_presence", selectedDays.has_student_presence ? 1 : 0);
		formData.append("has_teacher_presence", selectedDays.has_teacher_presence ? 1 : 0);

		fetch("/" + props.lang + "/admin/year-calendar/" + props.year + "/" + props.versionId + "/batch-update", {
			method : "POST",
			body : formData,
			headers: new Headers({
				"X-CSRF-TOKEN": token,
			})
		})
		.then(res => res.json())
		.then(res => {
			updateShowPleaseWait(false);
			updateSelectedDays(null);
			refreshData();
		});
	}



	return <div>
				{
					lang != null && months != null && days != null ?
						<div>
							{
								showClearSchoolNumber ?
									<form action="" method="post" onSubmit={confirmClearNumber}>
										<ModalComp lang={lang} title={lang.gen["Attention"]} confirmIsSubmit={true} closeAction={() => updateShowClearSchoolNumber(false)}>
											<p>
												{lang.gen["Clear School Day Numbers"]} ?
											</p>
										</ModalComp>
									</form>
								:
								showPleaseWait ?
									<ModalComp lang={lang} title={lang.gen["Please Wait"]}>
										{lang.gen["Please Wait"]}...
									</ModalComp>
								:
								showModalTriggerSchoolDays ?
									<form action="" method="post" onSubmit={confirmTrigger}>
										<ModalComp lang={lang} title={selectedDay.date} confirmIsSubmit={true} closeAction={() => updateShowModalTriggerSchoolDays(false)}>
											<p>
												{lang.gen["Start Cascade At"]}
											</p>
											<div>
												<input type="number" min="1" max="9" className='form-control' value={schoolDayCascade} onChange={e => updateSchoolDayCascade(e.target.value)} />
											</div>
										</ModalComp>
									</form>
								:
								selectedDays != null ?
									<form action="" method="post" onSubmit={confirmDaysUpdate}>
										<ModalComp lang={lang} title={selectedDays.year + "-" + selectedDays.month + " : " + selectedDays.day_start + " " + lang.gen["To"] + " " + selectedDays.day_end} confirmIsSubmit={true} closeAction={() => updateSelectedDays(null)}>
										<div className='row mt-1'>
												<div className='col-4'>
													{lang.gen["Has Student Presence"]}
												</div>
												<div className='col-3 text-left'>
													<input type="checkbox" className='me-2' name="has_student_presence" value={selectedDays.has_student_presence} checked={selectedDays.has_student_presence} onChange={e => updateSelectionDaysParam("has_student_presence", e.target.checked)} />
												</div>
											</div>
											<div className='row mt-3'>
												<div className='col-4'>
													{lang.gen["Has Teacher Presence"]}
												</div>
												<div className='col-3'>
													<input type="checkbox" className='me-2' name="has_teacher_presence" value={selectedDays.has_teacher_presence} checked={selectedDays.has_teacher_presence} onChange={e => updateSelectionDaysParam("has_teacher_presence", e.target.checked)} />
												</div>
											</div>
										</ModalComp>
									</form>
								:
								selectedDay != null ?
									<form action="" method="post" onSubmit={confirmUpdate}>
										<ModalComp lang={lang} width={"700px"} title={selectedDay.date} confirmIsSubmit={true}
											closeAction={() => updateSelectedDay(null)}
											otherAction={selectedDay.school_day != null && selectedDay.school_day > 0 ? () => triggerSchoolDays() : null}
											otherActionTxt={lang.gen["Trigger School Days Cascade"]}>
											<div className='row mt-1'>
												<div className='col-4'>
													{lang.gen["Has Student Presence"]}
												</div>
												<div className='col-3 text-left'>
													<input type="checkbox" className='me-2' name="has_student_presence" value={selectedDay.has_student_presence} checked={selectedDay.has_student_presence} onChange={e => togglePresence("has_student_presence", e.target.checked)} />
												</div>
											</div>
											<div className='row mt-3'>
												<div className='col-4'>
													{lang.gen["Has Teacher Presence"]}
												</div>
												<div className='col-3'>
													<input type="checkbox" className='me-2' name="has_teacher_presence" value={selectedDay.has_teacher_presence} checked={selectedDay.has_teacher_presence} onChange={e => togglePresence("has_teacher_presence", e.target.checked)} />
												</div>
											</div>
											{
												canHaveSchoolDay(selectedDay) ?
													<div className='row mt-4'>
														<div className='col-4 pt-2'>
															{lang.gen["School Day Number"]}
														</div>
														<div className='col-2'>
															<input type="number" min="1" max="9" className='form-control' value={selectedDay.school_day ?? ""} onChange={e => updateSchoolDay(e.target.value)} />
														</div>
													</div>
												:
													null
											}
											<div className='row mt-3'>
												{
													events.map(event => {
														return  <div key={"event-" + event.id} className='col-6'>
															<label className='border m-1 d-block p-2 user-select-none'><input type="checkbox" className='me-2' name="events[]" value={event.id} checked={selectedDay.events.indexOf(event.id) >= 0} onChange={e => addRemoveDayEvents(event.id, e.target.checked)} />{event["name_" + props.lang]}</label>
														</div>
													})
												}
											</div>
										</ModalComp>
									</form>
								:
									null
							}
							<div className='row mt-1'>
								<div className="d-flex justify-content-end mb-3 d-print-none" style={{height : '35px'}}>
									{
										days.filter(day => day.school_day != null && day.school_day > 0).length > 0 ?
											<button className='btn btn-sm btn-outline-primary' onClick={() => updateShowClearSchoolNumber(true)}>{ lang.gen["Clear School Day Numbers"] }</button>
										:
											null
									}
								</div>
								{
									months.map(month => {
										let monthDays = days.filter(day => new Date(day.year, day.month - 1, day.day).getMonth() + 1 == month.month);

										return <div key={month.short} className="col-3">
												<YearCalendarMonth lang={lang} month={month} dayClicked={(y, m, d) => dayClicked(y, m, d)} intervalSelected={(y, m, dayStart, dayEnd) => intervalSelected(y, m, dayStart, dayEnd)} days={monthDays} events={events} />
											</div>
									})
								}
							</div>
							<div className='fw-bold' style={{color:'#1f3864', fontSize:'0.8vw'}}>
								Légende | Legend
								<span className="d-print-none">
									 (<a href={"/" + props.lang + "/admin/year-calendar/events"}>
										{lang.gen["Event Types"]}
									</a>)
								</span>
							</div>
							<div className='row mt-1'>
								<div className='col-3 d-print-none' style={{fontSize:'0.7vw'}}>
									<span className='fa-solid fa-user faded me-2'></span>
									<span>{lang.gen["Has Student Presence"]}</span>
									<span className='d-print-none'> ({days.filter(d => d.has_student_presence).length})</span>
								</div>
								<div className='col-3 d-print-none' style={{fontSize:'0.7vw'}}>
									<span className='fa-regular fa-user faded me-2'></span>
									<span>{lang.gen["Has Teacher Presence"]}</span>
									<span className='d-print-none'> ({days.filter(d => d.has_teacher_presence).length})</span>
								</div>
								{
									events.map(event => {
										let icon = event.icon ?? "fa-solid fa-circle";

										return days.filter(d => d.events.indexOf(event.id) >= 0).length > 0 ?
													<div key={"legend-event-" + event.id} className='col-3 d-flex' style={{fontSize: '0.725vw'}}>
														<div className='text-center' style={{width: '1.5vw'}}>
															<span className={'me-2 ' + icon} style={{color: event.color}}></span>
														</div>
														<span>{event["name_fr"]} | {event["name_en"]}</span>
														<span className='d-print-none'> ({days.filter(d => d.events.indexOf(event.id) >= 0).length})</span>
													</div>
												:
													null
									})
								}
							</div>
						</div>
					:
						null
				}
		</div>
}