import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import {show} from './modal';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export const load = (lang, nodeId, widgetType, isTeacherAdmin) => {
    ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <SurveillanceSchedule lang={lang} widgetType={widgetType} isTeacherAdmin={isTeacherAdmin} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
}

function SurveillanceSchedule (props) {
    const [lang, updateLang] = useState(null);
	const [date, updateDate] = useState(new Date());
    const [overseers, updateOverseers] = useState(null);
    const [comments, updateComments] = useState(null);
    const [periods, updatePeriods] = useState(null);
    const [schedules, updateSchedules] = useState([]);
    const [absenceReasons, updateAbsenceReasons] = useState([]);
	const [selectedOverseer, updateSelectedOverseer] = useState(null);
	const [selectedType, updateSelectedType] = useState(null);
	const [selectedTimeMin, updateSelectedTimeMin] = useState(null);
	const [selectedTimeMax, updateSelectedTimeMax] = useState(null);
	const [coverageFailList, updateCoverageFailList] = useState(null);

	let currentDate = new Date().toJSON().slice(0, 10);

	const fetchData = () => {
		fetch("/js/" + props.lang + "/translation.js")
		.then(res => res.json())
		.then(res => {
			updateLang(res);
			changeDate(currentDate);
		});
	}

    useEffect(() => {
		fetchData();
	}, []);

	const changeDate = newDate => {
		if (newDate == null || newDate === "") {
			newDate = currentDate;
		}

		let types = ["Normal", "Pause"];

		if (props.widgetType === "daily") {
			types = ["Normal", "Pause", "Absence", "Overtime"];
		}

		let formData = new FormData();
		formData.append("types", types);

		let url = "/surveillance/schedule/data/" + newDate;

		if (!props.isTeacherAdmin) {
			url = "/surveillance/global-view/data";
		}

		fetch("/" + props.lang + url, {
			method : "POST",
			body : formData,
			headers: new Headers({
				"X-CSRF-TOKEN": token
			})
		})
		.then(res => res.json())
		.then(res => {
			updateComments(res.comments);
			updateAbsenceReasons(res.absenceReasons);
			updateSchedules(res.schedules);
			updateDate(res.date);
			updateOverseers(res.overseers);
			updatePeriods(res.periods);
			updateCoverageFailList(res.coverageFailList);
		})
	}

	const createOverseerLine = (container, addEmpty = false) => {
		let row = document.createElement("div");
		row.classList.add("row", "mb-1");

		let label = document.createElement("label");
		label.classList.add("col-sm-6", "pt-2");
		label.innerText = lang.gen["overseer"];

		let selectDiv = document.createElement("div");
		selectDiv.classList.add("col-sm-6");
		let select = document.createElement("select");
		select.classList.add("form-select");
		selectDiv.append(select);

		if (addEmpty) {
			let option = document.createElement("option");
			option.value = ""
			option.innerText = "--";
			select.append(option);
		}

		overseers.forEach(overseer => {
			let option = document.createElement("option");
			option.value = overseer.id
			option.innerText = overseer.last_name + ", " + overseer.first_name;
			select.append(option);
		})

		row.append(label);
		row.append(selectDiv);
		container.append(row);

		return select;
	}

	const createSelectLine = (container, list, addEmpty) => {
		let row = document.createElement("div");
		row.classList.add("row", "mb-1");

		let selectDiv = document.createElement("div");
		selectDiv.classList.add("col-sm-12");
		let select = document.createElement("select");
		select.classList.add("form-select");
		selectDiv.append(select);

		if (addEmpty) {
			let option = document.createElement("option");
			option.value = ""
			option.innerText = "--";
			select.append(option);
		}

		list.forEach(item => {
			let option = document.createElement("option");
			option.value = item.id;
			option.innerText = item.name;
			select.append(option);
		})

		row.append(selectDiv);
		container.append(row);

		return select;
	}

	const createInputLine = (container, labelText, type) => {
		let row = document.createElement("div");
		row.classList.add("row", "mb-1");

		let label = document.createElement("label");
		label.classList.add("col-sm-6", "pt-2");
		label.innerText = labelText;

		let selectDiv = document.createElement("div");
		selectDiv.classList.add("col-sm-6");
		let input = document.createElement("input");
		input.type = type;
		input.classList.add("form-control");
		selectDiv.append(input);

		row.append(label);
		row.append(selectDiv);
		container.append(row);

		return input;
	}

	const createReasonLine = (container) => {
		let row = document.createElement("div");
		row.classList.add("row", "mb-1");

		let label = document.createElement("label");
		label.classList.add("col-sm-6", "pt-2");
		label.innerText = lang.gen["Absence Reason"];

		let selectDiv = document.createElement("div");
		selectDiv.classList.add("col-sm-6");
		let select = document.createElement("select");
		select.classList.add("form-select");
		selectDiv.append(select);

		absenceReasons.forEach(absenceReason => {
			let option = document.createElement("option");
			option.value = absenceReason.id
			option.innerText = absenceReason.name;
			select.append(option);
		})

		row.append(label);
		row.append(selectDiv);
		container.append(row);

		return select;
	}

	const createNoteLine = (container) => {
		let row = document.createElement("div");
		row.classList.add("row", "mb-1");

		let label = document.createElement("textarea");
		label.classList.add("col-sm-12", "mt-2", "form-control");
		label.placeholder = lang.gen["Notes"];
		row.append(label);

		container.append(row);

		return label;
	}

	const toTime = seconds => {
		var date = new Date(null);
		date.setSeconds(seconds);
		return date.toISOString().substring(11, 16);
	}

	const showDisplayOrderModal = () => {
		let hoverElem = null;

		function dragStart(event) {
			hoverElem = null;
			event.dataTransfer.setData("draggedItem", event.target.id);
		}

		function handleDragOver(event) {
			event.preventDefault();
		}

		function handleDrop(event) {
			const draggedItemId = event.dataTransfer.getData("draggedItem");
			const draggedItem = document.getElementById(draggedItemId);

			if (hoverElem != null && hoverElem != draggedItem) {
				let parentNode = draggedItem.parentNode;
				let idxDragged = 0;
				let idxHover = 0;

				parentNode.childNodes.forEach((node, idx) => {
					if (node == hoverElem) idxHover = idx;
					if (node == draggedItem) idxDragged = idx;
				})

				draggedItem.remove();

				if (idxHover < idxDragged) {
					parentNode.insertBefore(draggedItem, hoverElem);
				}
				else if (idxHover > idxDragged) {
					if (hoverElem.nextSibling != null) {
						parentNode.insertBefore(draggedItem, hoverElem.nextSibling);
					}
					else {
						parentNode.append(draggedItem);
					}
				}
			}
		}

		let div = document.createElement("div");
		let p = document.createElement("p");
		p.innerText = lang.gen["You can reorder the list by dragging and dropping each name in the list"]
		div.append(p);

		let dropZone = document.createElement("div");
		dropZone.addEventListener("dragover", handleDragOver, false);
		dropZone.addEventListener("drop", handleDrop, false);

		overseers.forEach(user => {
			let userDiv = document.createElement("div");
			userDiv.style.cursor = "ns-resize";
			userDiv.classList.add("fade-hover");
			userDiv.setAttribute("draggable", "true");
			userDiv.id = "user" + user.id;
			userDiv.setAttribute("data-user-id", user.id)
			userDiv.ondragover = () => {
				hoverElem = userDiv;
			}
			userDiv.addEventListener("dragstart", dragStart);
			userDiv.innerText = " - " + user.last_name + "n " + user.first_name
			dropZone.append(userDiv);
		})

		div.append(dropZone);

		show(lang.gen["Display Priority In Surveillance List"], div, modalView => {
			let ids = [];
			dropZone.childNodes.forEach(node => {
				ids.push(node.getAttribute("data-user-id"));
			})
			let formData = new FormData();
			formData.append("ids", JSON.stringify(ids));

			fetch("/" + props.lang + "/surveillance/save-display-order", {
				method : "POST",
				body : formData,
				headers: new Headers({
					"X-CSRF-TOKEN": token
				})
			})
			.then(res => res.json())
			.then(res => {
				window.location.reload();
			})
			.catch(err => {
				console.log(err)
			})
		});
	}

	const setWorkSchedule = (type, schedule = null, overSeerId = null, minDate = null, maxDate = null, minTime = null, maxTime = null, callback = null) => {
		let div = document.createElement("div");

		let selectOverseer = createOverseerLine(div);
		if (overSeerId != null) selectOverseer.value = overSeerId;

		let inputStartDate = createInputLine(div, lang.gen["Start Date"], "date");
		if (minDate != null) inputStartDate.value = minDate;

		let inputEndDate = createInputLine(div, lang.gen["End Date"], "date");
		if (maxDate != null) inputEndDate.value = maxDate;

		let inputStartTime = createInputLine(div, lang.gen["Start Time"], "time");
		if (minTime != null) inputStartTime.value = toTime(minTime);

		let inputEndTime = createInputLine(div, lang.gen["End Time"], "time");
		if (maxTime != null) inputEndTime.value = toTime(maxTime);

		let selectReason = null;
		if (type === "Absence") {
			selectReason = createReasonLine(div);

			if (schedule != null && schedule.absence_reason_id != null) {
				selectReason.value = schedule.absence_reason_id;
			}
		}

		let note = createNoteLine(div);
		note.value = schedule != null ? (schedule.note ?? "") : "";

		if (type === "Normal" || type === "Pause") {
			inputStartDate.parentNode.parentNode.style.display = "none";
			inputEndDate.parentNode.parentNode.style.display = "none";
			note.parentNode.style.display = "none";
		}

		let deleteAction = null;

		if (schedule != null && schedule.id != null) {
			if (minDate >= date) {
				deleteAction = (modalView) => {
					modalView.hide();
					confirmDelete(schedule.id);
				}
			}

			inputStartDate.min = minDate;
			inputEndDate.min = minDate;
		}
		else {
			inputStartDate.min = currentDate;
			inputEndDate.min = currentDate;
		}

		let title = lang.gen["Schedule Type " + type];
		let updatingTodaysSchedule = false;

		if (props.widgetType === "daily" && type === "Normal") {
			updatingTodaysSchedule = true;
			title = lang.gen["Schedule Type Today " + type];
		}

		show(title, div, modalView => {
			if (inputStartDate.value != "" && inputStartTime.value != "" && inputEndTime.value != "" &&
				(inputEndDate.value === "" || inputStartTime.value <= inputEndTime.value)) {
				let formData = new FormData();
				formData.append("user_id", selectOverseer.value);
				formData.append("type", type);
				formData.append("date_min", inputStartDate.value);
				formData.append("date_max", inputEndDate.value);
				formData.append("time_min", inputStartTime.value);
				formData.append("time_max", inputEndTime.value);
				formData.append("note", note.value);

				if (type == "Absence") {
					formData.append("absence_reason_id", selectReason.value);
				}

				if (schedule != null && schedule.id != null) {
					formData.append("id", schedule.id);
				}

				let url = "/" + props.lang + "/surveillance/schedule/save";

				if (updatingTodaysSchedule) {
					url = "/" + props.lang + "/surveillance/schedule/update-day-schedule";
				}

				fetch(url, {
					method : "POST",
					body : formData,
					headers: new Headers({
						"X-CSRF-TOKEN": token
					})
				})
				.then(res => res.json())
				.then(res => {
					modalView.hide();

					if (callback !== null) {
						callback();
					}

					changeDate(date);
				})
				.catch(err => {
					console.log(err)
				})
			}
		}, null, deleteAction);
	}

	const startSelectPeriod = (overseerId, period, type) => {
		updateSelectedOverseer(overseerId);
		updateSelectedType(type);
		updateSelectedTimeMin(period.time_min);
		updateSelectedTimeMax(period.time_max);
	}

	const checkSelectPeriod = (overseerId, period, type) => {
		if (selectedOverseer === overseerId && selectedType === type) {
			if (selectedTimeMin <= period.time_min) {
				let currentMaxIdx = -1;
				let valid = selectedTimeMax >= period.time_max;

				periods.forEach((p, i) => {
					if (p.time_max === selectedTimeMax) {
						currentMaxIdx = i;
					}
					else if (currentMaxIdx != -1) {
						if (p === period && currentMaxIdx + 1 === i) {
							valid = true;
						}
					}
				});

				if (valid) {
					updateSelectedTimeMax(period.time_max);
				}
			}
		}
	}

	const processSelectedPeriods = (shiftIsDown = false) => {
		if (selectedOverseer != null) {
			if (shiftIsDown && props.widgetType === "daily" && selectedType === "Absence") {
				let div = document.createElement("div");
				div.innerHTML = lang.gen["Update Today's Pause Period"] + "?";
				show(lang.gen["Change Pause Period"], div, modalView => {
					let formData = new FormData();
					formData.append("user_id", selectedOverseer);
					formData.append("type", "Pause");
					formData.append("date", date);
					formData.append("time_min", selectedTimeMin);
					formData.append("time_max", selectedTimeMax);

					fetch("/" + props.lang + "/surveillance/schedule/update-lunch", {
						method : "POST",
						body : formData,
						headers: new Headers({
							"X-CSRF-TOKEN": token
						})
					})
					.then(res => res.json())
					.then(res => {
						modalView.hide();

						changeDate(date);
					})
					.catch(err => {
						console.log(err)
					})
				});
			}
			else {
				setWorkSchedule(selectedType, null, selectedOverseer, date, date, selectedTimeMin, selectedTimeMax, () => {
					cancelSelection()
				});
			}
		}
	}

	const cancelSelection = () => {
		updateSelectedOverseer(null);
		updateSelectedType(null);
		updateSelectedTimeMin(null);
		updateSelectedTimeMax(null);
	}

	const confirmDelete = (scheduleId) => {
		let div = document.createElement("div");
		div.innerHTML = lang.gen["Delete"] + "?";

		show(lang.gen["Delete"], div, modalView => {
			fetch("/" + props.lang + "/surveillance/schedule/delete/" + scheduleId, {
				method : "POST",
				headers: new Headers({
					"X-CSRF-TOKEN": token
				})
			})
			.then(res => res.json())
			.then(res => {
				modalView.hide();
				changeDate(date);
			})
		});
	}

	const checkUpdateSchedule = (event, schedule) => {
		if (schedule != null && schedule.id != null) {
			if (props.widgetType === "schedule") {
				confirmDelete(schedule.id);
			}
			else {
				setWorkSchedule(schedule.type, schedule, schedule.user_id, schedule.date_min, schedule.date_max, schedule.time_min, schedule.time_max);
			}
		}

		event.preventDefault();
	}

	const goNextDate = direction => {
		let tmpDate = new Date(date);
		tmpDate.setDate(tmpDate.getDate() + direction);
		changeDate(tmpDate.toISOString().substring(0, 10));
	}

	const getColor = type => {
		let color = "bg-danger";

		if (type == "Normal") color = "bg-primary";
		else if (type == "Overtime") color = "bg-info";
		else if (type == "Absence") color = "bg-warning";
		else if (type == "Pause") color = "bg-secondary";

		return color;
	}

	const getDragType = (overseerId, overType) => {
		let type = null;

		if (props.widgetType === "schedule") {
			if (overType === "Normal") {
				type = "Pause";
			}
			else {
				let current = schedules.find((schedule) => schedule.user_id === overseerId && schedule.type === "Normal");

				if (current == null) {
					type = "Normal";
				}
			}
		}
		else {
			if (overType === "Normal") type = "Absence";
			else if (overType === "Pause") type = "Overtime";
			else if (overType === null) type = "Overtime";
		}

		return type;
	}

	const setComment = (e, overseer, period, comment) => {
		e.stopPropagation();
		e.preventDefault();

		let textarea = document.createElement("textarea");
		textarea.classList.add("form-control");
		textarea.style.height = "200px";

		if (comment != null) {
			textarea.value = comment.comment;
		}

		if (overseer?.id != null) {
			show(lang.gen["Comment"], textarea, modalView => {
				let formData = new FormData();
				formData.append("period_id", period.id);
				formData.append("user_id", overseer.id);
				formData.append("comment", textarea.value);
				formData.append("date", date);
				fetch("/" + props.lang + "/surveillance/periods/set-comment", {
					method : "POST",
					body: formData,
					headers: new Headers({
						"X-CSRF-TOKEN": token
					})
				})
				.then(res => res.json())
				.then(res => {
					modalView.hide();
					changeDate(date);
				})
			});
		}
	}

	const setForcedAssignation = (e, period, overseer, locationFirstId) => {
		e.stopPropagation();
		e.preventDefault();

		let locations = [];

		const addLoc = loc => {
			if (loc.location_id_list != null && loc.location_id_list.length > 0) {
				let name = "";

				loc.location_list.forEach(l => {
					if (name.length > 0) {
						name += ", ";
					}
					name += l;
				});

				locations.push({
					id : loc.location_id_list[0],
					name : name,
				});
			}
		}

		let div = document.createElement("div");

		let info = document.createElement("div");
		info.classList.add("text-center", "mt-2", "mb-4");
		info.innerHTML = lang.gen["Where should the following supervisor be?"] + "<br> <strong>" + overseer.name + "</strong>";
		div.append(info);


		overseers.forEach(o => {
			o.daySchedule.forEach(s => {
				if (s.period_id == period.id) {
					addLoc(s);
				}
			})
		})

		coverageFailList.forEach(per => {
			if (per.period_id == period.id) {
				addLoc(per);
			}
		})

		locations.sort((a, b) => {
			return a.name > b.name;
		})

		let select = createSelectLine(div, locations, true);

		if (locationFirstId != null) {
			select.value = locationFirstId;
		}

		show(lang.gen["Force assignation"], div, modalView => {
			let formData = new FormData();
			formData.append("period_id", period.id);
			formData.append("location_id", select.value);
			formData.append("user_id", overseer.id);
			formData.append("date", date);

			fetch("/" + props.lang + "/surveillance/schedule/force-assign", {
				method : "POST",
				body: formData,
				headers: new Headers({
					"X-CSRF-TOKEN": token
				})
			})
			.then(res => res.json())
			.then(res => {
				modalView.hide();
				changeDate(date);
			})
		});
	}

	return <div onMouseLeave={e => cancelSelection()}>
				{
					lang != null && overseers && periods ?
						<div>
							{
								props.isTeacherAdmin ?
									<div className='d-sm-flex justify-content-between mb-2'>
										<div className='d-flex justify-content-start'>
											<button className="btn btn-sm btn-primary me-2" onClick={e => goNextDate(-1)}><span className='fa fa-chevron-left'></span></button>
											<input className='form-control form-control-sm' type="date" value={date} onChange={e => changeDate(e.target.value)} />
											<button className="btn btn-sm btn-primary ms-2" onClick={e => goNextDate(1)}><span className='fa fa-chevron-right'></span></button>
										</div>
										<div className='d-flex justify-content-end'>
											<button className='btn btn-sm btn-primary me-2' onClick={e => setWorkSchedule("Normal", null, null, props.widgetType === "daily" ? date : currentDate)}>
												{
													props.widgetType === "daily" ?
														lang.gen["Schedule Type Today Normal"]
													:
														lang.gen["Set Work Schedule"]
												}
											</button>
										</div>
									</div>
								:
									null
							}
							<table className='table table-sm text-sm'>
								<thead>
									<tr style={{
											position: "sticky",
											top : "45px",
											zIndex : 300,
											backgroundColor : "white",
									 	}}>
										<th></th>
										{
											periods.map(p => {
												return <th key={p.id} className="text-center">
													<div>
														{p.name}<br />
														<small>{p.time_min_formatted} - {p.time_max_formatted}</small>
													</div>
												</th>
											})
										}
									</tr>
								</thead>
								<tbody>
									{
										overseers.map(overseer => {
											return <tr key={"o" + overseer.id}>
												<td>
													<div style={{ minHeight:"55px" }}>
														{
															props.isTeacherAdmin ?
																<a href={"/" + props.lang + "/users/" + overseer.id} className="text-decoration-none">{overseer.name}</a>
															:
																overseer.name
														}
													</div>
													</td>
												{
													periods.map(p => {
														let type = null;
														let typeColor = "";
														let overSchedule = null;

														schedules.forEach(schedule => {
															if (type == null) {
																if (overseer.id == schedule.user_id &&
																	schedule.time_min <= p.time_min && schedule.time_max >= p.time_max) {
																	type = schedule.type;
																	overSchedule = schedule;
																	typeColor = getColor(type);

																	if (type === "Absence" && overSchedule.date_min === overSchedule.date_max &&
																		schedule.time_min === p.time_min && schedule.time_max === p.time_max) {
																		typeColor = "bg-dark-orange border ";

																		absenceReasons.forEach(abs => {
																			if (abs.id == schedule.absence_reason_id) {
																				if (abs.exclude_from_surv_reports) {
																					typeColor = "bg-light-blue border ";
																				}
																			}
																		})
																	}
																}
															}
														});

														let typeSel = getDragType(overseer.id, type);

														if (overseer.id === selectedOverseer && typeSel != null) {
															if (selectedTimeMin <= p.time_min && selectedTimeMax >= p.time_max) {
																typeColor = getColor(typeSel);
															}
														}

														const canDrag = props.isTeacherAdmin && typeSel != null;
														const canClick = props.isTeacherAdmin && overSchedule != null && !canDrag;

														let location = "";
														let locationFirstId = null;
														let showAffinityIssue = false;
														let showNonAffinity = false;
														let showForcedAssignation = false;
														let comment = null;

														if (props.widgetType === "daily") {
															overseer.daySchedule.forEach(s => {
																if (s.period_id == p.id) {
																	if (s.location_id_list != null && s.location_id_list.length > 0) {
																		locationFirstId = s.location_id_list[0];
																	}

																	if (s.unavailable_reason !== null && s.unavailable_reason !== "Pause") {
																		if (props.isTeacherAdmin) {
																			location = s.unavailable_reason;
																		}
																	}
																	else {
																		s.location_list.forEach(loc => {
																			if (location .length > 0) {
																				location += ", ";
																			}
																			location += loc;
																		});

																		if (s.location_list.length > 0) {
																			showAffinityIssue = !s.is_first_affinity;
																			showNonAffinity = s.is_non_affinity;
																			showForcedAssignation = s.is_forced_assignation;
																		}
																	}
																}
															});
														}

														comment = comments.find(c => c.period_id == p.id && c.user_id == overseer.id);

														return <td key={overseer.id + "-" + p.id}
																	style={{width: (100/(periods.length + 2)) + "%", verticalAlign:"middle"}}
																	className={"parent-hover-control parent-hover-control2  text-center position-relative " + typeColor + " " + (props.isTeacherAdmin && (canDrag || canClick) ? "cursor-pointer" : "")}
																	onClick={e => canClick ? checkUpdateSchedule(e, overSchedule) : null}
																	onMouseUp={e => !canClick ? processSelectedPeriods(e.shiftKey) : null}
																	onMouseDown={() => !canClick && canDrag ? startSelectPeriod(overseer.id, p, typeSel) : null}
																	onMouseEnter={() => type != typeSel || overSchedule == null ? checkSelectPeriod(overseer.id, p, typeSel) : null}
																	>
																	<div className="text-xs text-white">
																		{
																			showNonAffinity ?
																				<span className='me-1 fa fa-triangle-exclamation text-dark'></span>
																			:
																			showAffinityIssue ?
																				<span className='me-1 fa fa-circle-exclamation text-dark'></span>
																			:
																				null
																		}
																		{ location }
																	</div>
																	{
																		comment != null ?
																			<div className='position-absolute p-0'
																				style={{top : 1, left : 1, width: 20, height: 20, zIndex: 200, pointerEvents: 'none'}}
																				>
																				<div className='d-flex justify-content-center align-items-center h-100'>
																					<span className='fa fa-comment p-0 text-warning text-xs'></span>
																				</div>
																				<div className='parent-hover-visible2 p-2 border rounded position-absolute bg-white text-xs text-start' style={{bottom: '150%', left: -150, width: 300}}>
																					<span className='text-start w-100' dangerouslySetInnerHTML={{__html : comment.comment.replaceAll("\n", "<br>")}}></span>
																				</div>
																			</div>
																		:
																			null
																	}
																	{
																		props.isTeacherAdmin && showForcedAssignation &&
																			<div className='position-absolute p-0'
																				style={{top : 1, right : 1, width: 20, height: 20, zIndex: 200, pointerEvents: 'none'}}
																				>
																				<div className='d-flex justify-content-center align-items-center h-100'>
																					<span className='fa fa-user p-0 text-warning text-xs'></span>
																				</div>
																			</div>
																	}
																	{
																		props.widgetType === "daily" && overSchedule && selectedTimeMin == null && props.isTeacherAdmin && (canDrag || canClick) ?
																			<>
																				<div className='parent-hover-visible position-absolute rounded bg-secondary p-0'
																					style={{top : 1, left : 1, width: 20, height: 20, zIndex: 10}}
																					onMouseDown={e => setComment(e, overseer, p, comment)}
																					>
																					<div className='d-flex justify-content-center align-items-center h-100'>
																						<span className='fa fa-comment p-0 text-warning text-xs'></span>
																					</div>
																				</div>
																				{
																					type === "Normal" || type === "Overtime" ?
																						<div className='parent-hover-visible position-absolute border border-secondary rounded bg-secondary p-0'
																							style={{top : 1, right : 1, width: 20, height: 20}}
																							onMouseDown={e => setForcedAssignation(e, p, overseer, locationFirstId)}
																							>
																							<div className='d-flex justify-content-center align-items-center h-100'>
																								<span className='fa fa-user p-0 text-warning text-xs'></span>
																							</div>
																						</div>
																					:
																						null
																				}
																			</>
																		:
																			null
																	}
																</td>
													})
												}
											</tr>
										})
									}
									{
										props.widgetType === "daily" && coverageFailList != null && coverageFailList.length > 0 ?
											<tr>
												<td>{lang.gen["Coverage Problems"]}</td>
												{
													periods.map(period => {
														return <td key={"def-" + period.id}>
																		{
																			coverageFailList.sort((a, b) => b.notify_option - a.notify_option).map(l => {
																				if (l.period_id == period.id) {
																					return 	<React.Fragment key={"list-" + period.id + "-" + JSON.stringify(l.location_list)}>
																								{
																									l.location_list.map(name => {
																										return 	<div className='text-xs border-bottom' key={period.id + "-" + name}>
																													{
																														l.notify_option ?
																															<span className='me-1 fa fa-triangle-exclamation text-danger'></span>
																														:
																															null
																													}
																													{name}
																												</div>
																									})
																								}
																							</React.Fragment>
																				}
																				else {
																					return null;
																				}
																			})
																		}
																</td>
													})
												}
											</tr>
										:
											null
									}
								</tbody>
							</table>
							<div className='row'>
								<div className='col-6'>
									<div className='d-flex align-items-center justify-content-start'><span className='bg-primary d-inline-block me-2' style={{height:"15px", width:"25px"}}></span> {lang.gen["Normal Schedule"]}</div>
									<div className='d-flex align-items-center justify-content-start'><span className='bg-secondary d-inline-block me-2' style={{height:"15px", width:"25px"}}></span> {lang.gen["Pause"]}</div>
									{
										props.widgetType === "daily" ?
											<div>
												<div className='d-flex align-items-center justify-content-start'><span className='bg-warning d-inline-block me-2' style={{height:"15px", width:"25px"}}></span> {lang.gen["Absence"]}</div>
												<div className='d-flex align-items-center justify-content-start'><span className='bg-dark-orange d-inline-block me-2' style={{height:"15px", width:"25px"}}></span> {lang.gen["Single Period Absence"]}</div>
												<div className='d-flex align-items-center justify-content-start'><span className='bg-info d-inline-block me-2' style={{height:"15px", width:"25px"}}></span> {lang.gen["Overtime"]}</div>
												<div className='d-flex align-items-center justify-content-start'><span className='me-1 fa fa-circle-exclamation d-inline-block ms-1 me-1 text-dark' style={{height:"15px", width:"25px"}}></span>{lang.gen["Not First Assignation"]}</div>
												<div className='d-flex align-items-center justify-content-start'><span className='me-1 fa fa-triangle-exclamation d-inline-block ms-1 me-1 text-dark' style={{height:"15px", width:"25px"}}></span>{lang.gen["Non Affinity Assignation"]}</div>
											</div>
										:
											null
									}
								</div>
								{
									props.widgetType === "daily" && props.isTeacherAdmin ?
										<div className='col-6 text-end faded'>
											<div>
												{lang.gen["shift+click To Change Todays Lunch"]}
											</div>
											<div>
												<span className='text-decoration-underline cursor-pointer' onClick={() => {showDisplayOrderModal()}}>
													{lang.gen["Change supervisor's display order"]}
												</span>
											</div>
										</div>
									:
										null
								}
							</div>
						</div>
					:
						null
				}
		</div>
}