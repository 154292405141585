import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import {show} from './modal';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export const load = (lang, nodeId) => {
	ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <Algo lang={lang} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
};

function Algo (props) {
	const [lang, updateLang] = useState(null);
	const [running, updateRunning] = useState(false);
	const [result, updateResult] = useState(null);
	const timer = useRef(null);

	useEffect(() => {
		fetch("/js/" + props.lang + "/translation.js")
		.then(res => res.json())
		.then(res => {
			updateLang(res);
		});
	}, [props.lang]);

	const start = () => {
		timer.current = new Date().getTime();

		fetch("/" + props.lang + "/admin/assign-algo/run", {
			method : "POST",
			headers: new Headers({
                "X-CSRF-TOKEN": token
            })
		})
		.then(res => res.json())
		.then(res => {
			timer.current = new Date().getTime() - timer.current;
			updateResult(res);
		});

		updateRunning(true);
	}

	const showUsers = userIds => {
		if (userIds.length > 0) {
			let div = document.createElement("div");
			div.style.maxHeight = "50vh";
			div.style.display = "relative";
			div.style.overflow = "auto";

			let ul = document.createElement("ul");
			div.append(ul);

			Utils.fetchBaseModalData(props.lang, "substitutes", (langTxt, teachers) => {
				userIds.forEach(id => {
					teachers.forEach(teacher => {
						if (id == teacher.id) {
							let li = document.createElement("li");
							let a = document.createElement("a");
							a.href = "/" + props.lang + "/users/" + teacher.id;
							a.target = "teacher";
							a.innerText = teacher.first_name + " " + teacher.last_name;
							li.append(a);
							ul.append(li);
						}
					})
				})

				show(lang.gen["Users"],div);
			});
		}
	}

	return 	<div>
				{
					lang != null ?
						<div>
						{
							!running ?
								<div className="d-flex justify-content-center">
									<button className="btn btn-lg btn-primary" onClick={start}>{lang.gen['Start'] }</button>
								</div>
							:
								result == null ?
									<div className='fst-italic'>
										{ lang.gen['Please Wait'] }...
									</div>
								:
									<div>
										<table className='table table-striped'>
											<thead>
												<tr>
													<th>{ lang.gen['Date'] }</th>
													<th>{ lang.gen['Period'] }</th>
													<th>{ lang.gen['Teacher'] }</th>
													<th>{ lang.gen['Title'] }</th>
													<th>{ lang.gen['Status'] }</th>
													<th>{ lang.gen['Assign Rule'] }</th>
													<th>{ lang.gen['Substitute'] }</th>
													<th className='text-center'>{ lang.gen['Emails Sent Count'] }</th>
													<th className='text-center'>{ lang.gen['New Emails Count'] }</th>
												</tr>
											</thead>
											<tbody>
												{result.map((line, idx) => {
													return <tr key={idx}>
														<td>{line.date} ({line.school_day})</td>
														<td>{line.period}</td>
														<td>
															{
																line.teacher != null && line.teacher.length > 0 ?
																	<a href={"/" + props.lang + "/users/" + line.teacherId }>{line.teacher}</a>
																:
																	""
															}
														</td>
														<td>{line.title}</td>
														<td>{line.status}</td>
														<td>{line.substitute_assign_reason}</td>
														<td>
															{
																line.substitute != null && line.substitute.length > 0 ?
																	<a href={"/" + props.lang + "/users/" + line.substituteId }>{line.substitute}</a>
																:
																	""
															}
														</td>
														<td className={'text-center text-primary ' + (line.emails_sent.length > 0 ? "cursor-pointer" : "")} onClick={() => showUsers(line.emails_sent)}>{line.emails_sent.length}</td>
														<td className={'text-center text-primary ' + (line.new_emails_sent.length > 0 ? "cursor-pointer" : "")} onClick={() => showUsers(line.new_emails_sent)}>{line.new_emails_sent.length}</td>
													</tr>
												})}
											</tbody>
										</table>
										<div>
											<small>{timer.current/1000} sec.</small>
										</div>

									</div>
						}
						</div>
					:
						null
				}
			</div>
}