import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export const load = (lang, nodeId, url) => {
    ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <SelectionList lang={lang} url={url} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
}

export function SelectionList ({url, ...props}) {
    const [lang, updateLang] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [textFilter, setTextFilter] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [items, setItems] = useState([]);
	const [mainTitle, setMainTitle] = useState("");

	const updateList = () => {
		fetch(url)
		.then(response => response.json())
		.then(data => {
			setMainTitle(data.title);
			setItems(data.items);
			setSelectedItems(data.selected_items);
			setProcessing(false);
		})
	}

    useEffect(() => {
		if (lang == null) {
			fetch("/js/" + props.lang + "/translation.js")
			.then(res => res.json())
			.then(res => {
				updateLang(res);
			});
		}

		updateList();
    }, []);

    const updateSelection = (wasAdded, item) => {
        if (!processing && items?.length > 0) {
			setProcessing(true);

			let formData = new FormData();
			formData.append("item_id", item.id);
			formData.append("added", wasAdded ? 1 : 0);
			formData.append("_token", token);

			fetch(url, {
				method: "post",
				body: formData,
			})
			.then(response => response.json())
			.then(response => {
				updateList();
			})
        }
    }

    function Item({item, itemClicked}) {
        return <div className={"p-1 border my-1 hover-bg-light select-none " + (items?.length > 0 ? "cursor-pointer" : "") + (item.status === "inactive" ? " opacity-50 " : "")} onClick={itemClicked}>
                    {item.name}
					{
						item.notes?.length > 0 ?
							<div className='text-xs'>
								{item.notes}
							</div>
						:
							null
					}
                </div>
    }

    return (
            <div className='mt-5 border-t'>
                <section className='pt-3'>
                    <h3 className='text-lg cursor-pointer d-flex justify-content-between select-none' onClick={() => setExpanded(!expanded)}>
						<div>
							<span className="w-5 mr-2 text-lg">
								<span className={"fa fa-solid me-2 " + (expanded ? "fa-chevron-down" : "fa-chevron-right")} />
							</span>
							{mainTitle}
						</div>
                        <span className="ml-auto">
                            ({selectedItems.length}{items?.length > 0 ? "/" + items.length : ""})
                        </span>
                    </h3>
                    {
                        expanded ?
                            <div>
                                <div className='mb-2 d-flex'>
                                    <div className='' style={{flexBasis:"50%", flexGrow: 0}}>
                                        <input type="text" placeholder={lang.gen["Search"]} className='form-control' value={textFilter} onChange={e => setTextFilter(e.target.value)} />
                                    </div>
                                </div>
                                <div className={"d-flex " + (processing ? "opacity-50" : "")}>
                                    <div className='me-2' style={{flexBasis:"50%"}}>
                                        <h4 className='text-center'>{lang.gen["List"]}</h4>
										<div className={"p-2 border shadow-sm mt-1 overflow-auto text-sm"} style={{height:250}}>
                                            {
                                                items.filter(item => item.name.toLowerCase().indexOf(textFilter) != -1).map(item => {
                                                    if (!selectedItems.find(i => i.id == item.id)) {
                                                        return <Item key={"item-" + item.id} item={item} itemClicked={e => updateSelection(true, item)} />
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div style={{flexBasis:"50%"}}>
                                        <h4 className='text-center'>{lang.gen["Selection"]}</h4>
                                        <div className={"p-2 border shadow-sm mt-1 overflow-auto text-sm"} style={{height:250}}>
                                            {
                                                selectedItems.filter(item => item.name.toLowerCase().indexOf(textFilter) != -1).map(item => {
                                                    return <Item key={"item-" + item.id} item={item} itemClicked={e => updateSelection(false, item)} />
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            null
                    }
                </section>
            </div>
    )
}