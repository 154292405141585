import { Modal } from 'bootstrap';

export const show = (title, body, confirmAction, confirmText = null, deleteAction = null) => {
    let modal = document.querySelector("#site-modal");
    let modalView = new Modal(modal);
    modal.querySelector(".modal-title").textContent = title;
    modal.querySelector(".btn-modal-main").style.display = "block";
    modal.querySelector(".btn-modal-main").disabled = false;
    modal.querySelector(".btn-modal-delete").disabled = false;
    modal.querySelector(".btn-modal-close").disabled = false;

    if (typeof body === 'string') {
        modal.querySelector(".modal-body").innerHTML = body;
    }
    else {
        modal.querySelector(".modal-body").innerHTML = "";
        modal.querySelector(".modal-body").append(body);
    }

    if (confirmAction == null) {
        modal.querySelector(".btn-modal-main").classList.add("d-none");
    }
    else {
        modal.querySelector(".btn-modal-main").onclick = () => {
            modal.querySelector(".btn-modal-main").disabled = true;
            modal.querySelector(".btn-modal-close").disabled = true;
            confirmAction(modalView);
        }
        modal.querySelector(".btn-modal-main").classList.remove("d-none");
    }

    if (confirmText != null) {
        modal.querySelector(".btn-modal-main").innerText = confirmText;
    }

    if (deleteAction != null) {
        modal.querySelector(".btn-modal-close").classList.add("d-none");
        modal.querySelector(".btn-modal-delete").onclick = () => {
            modal.querySelector(".btn-modal-delete").disabled = true;
            modal.querySelector(".btn-modal-close").disabled = true;
            deleteAction(modalView);
        }
        modal.querySelector(".btn-modal-delete").classList.remove("d-none");
    }
    else {
        modal.querySelector(".btn-modal-delete").classList.add("d-none");
        modal.querySelector(".btn-modal-close").classList.remove("d-none");
    }

    modalView.show();

    return modalView;
}