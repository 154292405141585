import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default function YearCalendarMonth (props) {
	const [startDayOfWeek, updateStartDayOfWeek] = useState(null);
	const [numberOfDays, updateNumberOfDays] = useState(null);
	const [startSelectionDate, updateStartSelectionDate] = useState(null);
	const [endSelectionDate, updateEndSelectionDate] = useState(null);

	useEffect(() => {
		updateNumberOfDays(new Date(props.month.year, props.month.month, 0).getDate());
		updateStartDayOfWeek(new Date(props.month.year, props.month.month - 1, 1).getDay()); // 0 = Sunday

	}, [props.month]);

	const setStartSelectionDate = (e, day) => {
		e.preventDefault();

		if (day != "") {
			updateStartSelectionDate(day);
			updateEndSelectionDate(day);
		}
	}

	const setEndSelectionDate = (e, day) => {
		e.preventDefault();

		if (startSelectionDate != null && day != "" && day != null) {
			updateEndSelectionDate(day);
		}
	}

	const setDoneSelecting = e => {
		e.preventDefault();

		if (startSelectionDate != null && Math.abs(startSelectionDate - endSelectionDate) > 0) {
			props.intervalSelected(props.month.year, props.month.month, startSelectionDate, endSelectionDate);
		}

		updateStartSelectionDate(null);
		updateEndSelectionDate(null);
	}

	const inSelectionRange = dayNo => {
		let inRange = false;

		if (startSelectionDate != null && dayNo != null && dayNo != "") {
			let dayOfMonth = new Date(props.month.year, props.month.month - 1, dayNo);

			if (dayOfMonth.getDay() > 0 && dayOfMonth.getDay() < 6 &&
				(startSelectionDate <= dayNo && endSelectionDate >= dayNo) ||
				(endSelectionDate <= dayNo && startSelectionDate >= dayNo)) {
				inRange = true;
			}
		}

		return inRange;
	}

	let dayTitles = ["D | S", "L | M", "M | T", "M | W", "J | T", "V | F", "S | S"];
	let fontSize = "0.8vw";
	let fontSizeSm = "0.7vw";

	return <div className='mb-4' onMouseLeave={(e) => setStartSelectionDate(e, null)}>
				<div className='text-uppercase text-center fw-bold' style={{color:'#ed7d31', fontSize: fontSize}}>{props.month.name} {props.month.year}</div>
				<div className='d-flex text-white' style={{backgroundColor:'#1f3864'}}>
					{
						[...Array(7).keys()].map((_, dayOfWeek) => {
							return <div className='text-center text-uppercase fw-bold' style={{flexBasis:"4vw", fontSize: fontSizeSm}} key={"day-title-" + dayOfWeek}>
										{dayTitles[dayOfWeek]}
								   </div>
						})
					}
				</div>
				<div>
					{
						[...Array(6).keys()].map((_, weekNo) => {

							return <div className='d-flex' key={"week-" + weekNo}>
								{
									[...Array(7).keys()].map((_, dayOfWeek) => {
										let dayExists = (weekNo > 0 || dayOfWeek + 1 > startDayOfWeek);
										let dayNo = dayExists ? (weekNo * 7 + dayOfWeek - startDayOfWeek + 1) : 0;
										let day = null;

										if (dayNo <= 0 || dayNo > numberOfDays) {
											dayNo = "";
										}
										else {
											day = props.days.find(d => d.day == dayNo);
										}

										return <div className={'border border-dark p-1 position-relative user-select-none ' + (inSelectionRange(dayNo) ? " bg-info" : "") + (dayNo != "" ? ' hover-bg-light' : '')}
													onClick={() => dayNo != "" ? props.dayClicked(props.month.year, props.month.month, dayNo) : ""}
													style={{height:'2.5vw', flexBasis:'4vw', backgroundColor : day != null ? null : "#ddd"}}
													key={weekNo + "-" + dayOfWeek}
													onMouseDown={e => setStartSelectionDate(e, dayNo)}
													onMouseOver={e => setEndSelectionDate(e, dayNo)}
													onMouseUp={e => setDoneSelecting(e)}
													>
													<span className='position-absolute' style={{left:"2px", bottom:"-2px", fontSize:fontSize}}>{dayNo}</span>
													{
														day != null ?
															<React.Fragment>
																{
																	day.has_student_presence && day.has_teacher_presence ?
																		<span className='position-absolute fa-solid fa-user-group faded d-print-none' style={{top:"1px", left:"2px", fontSize:fontSize}}></span>
																	:
																	day.has_teacher_presence ?
																		<span className='position-absolute fa-regular fa-user d-print-none' style={{top:"1px", left:"2px", fontSize:fontSize}}></span>
																	:
																	day.has_student_presence ?
																		<span className='position-absolute fa-solid fa-user d-print-none' style={{top:"1px", left:"2px", fontSize:fontSize}}></span>
																	:
																		null
																}
																{
																	day.school_day != null ?
																		<span className='position-absolute fw-bold' style={{bottom:"1.5vw", right:"2px", fontSize:fontSizeSm}}>{day.school_day}</span>
																	:
																		null
																}
																{
																	day.events.map((eventId, idx) => {
																		let evt = props.events.find(event => event.id == eventId);
																		let color = evt.color;
																		let icon = evt.icon ?? "fa-solid fa-circle";

																		return <span className={'position-absolute ' + icon} key={"sel-event-" + eventId} style={{fontSize: '0.75vw', bottom: ((idx % 2) * 1.35) + 0.1 + "vw", right: ((idx - (idx % 2))/2 * 1) + 0.11 + "vw", color: color}}></span>
																	})
																}
															</React.Fragment>
														:
															null
													}
											   </div>
									})
								}
							</div>
						})
					}
				</div>
				<div className='d-flex justify-content-between faded d-print-none'>
					<div style={{fontSize: fontSize}}>
						<span className='text-xxs fa-regular fa-user'></span> {props.lang.gen["Student Presence"]} : {props.days.filter(d => d.has_student_presence).length}
					</div>
					<div style={{fontSize: fontSize}}>
						<span className='text-xxs fa-solid fa-user'></span> {props.lang.gen["Teacher Presence"]} : {props.days.filter(d => d.has_teacher_presence).length}
					</div>
				</div>
		</div>
}