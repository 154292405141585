import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {show} from './modal';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

export const loadCourseSchedule = (courseId, lang, groupNumber, nodeId, editable) => {
	let teachers;
	let periods;

	const save = (periods) => {
		const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
		let formData = new FormData();
		formData.append("periods", JSON.stringify(periods));

		fetch("/" + lang + "/courses/" + courseId + "/groups/" + groupNumber + "/periods", {
			method : "POST",
			body : formData,
			headers: new Headers({
				"X-CSRF-TOKEN": token
			})
		})
		.then(res => res.json())
		.then(res => {
			window.location.reload();
		});
	}

	const newPeriodPrompt = (langDict, day, period, selectedRoom, selectedUserId, callback) => {
		let div = document.createElement("div");

		let label = document.createElement("label");
		label.classList.add( "p-1");
		label.innerText = langDict.gen["Room"];

		let input = document.createElement("input");
		input.placeholder = langDict.gen["Room"];
		input.classList.add("form-control", "room-input");

		let labelTeacher = document.createElement("label");
		labelTeacher.classList.add("mt-3","p-1");
		labelTeacher.innerText = langDict.gen["Teacher"];

		let select = document.createElement("select");
		select.classList.add("form-select", "teacher-id-select");

		teachers.forEach(teacher => {
			let option = document.createElement("option");
			option.value = teacher.id
			option.innerText = teacher.last_name + ", " + teacher.first_name;

			if (teacher.id == selectedUserId) {
				option.checked = true;
			}

			select.append(option);
		})

		periods.forEach(p => {
			input.value = p.room;
			select.value = p.user_id;
		});

		if (selectedRoom != null) {
			input.value = selectedRoom;
		}

		if (selectedUserId != null) {
			select.value = selectedUserId;
		}

		div.append(label);
		div.append(input);
		div.append(labelTeacher);
		div.append(select);

		show(langDict.gen["Day"] + " " + day + ", " + langDict.gen["Period"] + " " + period, div, modalView => {
			modalView.hide();
			let teacherName = null;

			teachers.forEach(t => {
				if (select.value == t.id) {
					teacherName = t.first_name.substring(0, 1) + ". " + t.last_name;
				}
			})

			callback({
				"user_id" : select.value,
				"day" : day,
				"period" : period,
				"room" : input.value,
				"group" : groupNumber,
				"editable" : editable,
				"title" : input.value,
				"subTitle" : teacherName,
			});
		});
	}

	fetch("/" + lang + "/admin/list/teacher")
	.then(res => res.json())
	.then(t => {
		teachers = t;

		fetch("/" + lang + "/courses/" + courseId + "/groups/" + groupNumber + "/periods")
		.then(res => res.json())
		.then(p => {
			periods = p;

			periods.forEach(per => {
				per.editable = editable;
				per.title = per.room;

				teachers.forEach(t => {
					if (per.user_id == t.id) {
						per.subTitle = t.first_name.substring(0, 1) + ". " + t.last_name;
					}
				})
			})

			ReactDOM.render(
				<React.StrictMode>
					<React.Fragment>
						<CourseSchedule periods={periods} teachers={teachers} lang={lang} editable={editable} save={save} newPeriodPrompt={editable ? newPeriodPrompt : null} />
					</React.Fragment>
				</React.StrictMode>,
				document.getElementById(nodeId));
		});
	});
}

export const loadTeacherSchedule = (teacherId, lang, nodeId, isTeacherAdmin) => {
	let availableTypes = [];

	const save = periods => {
		const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
		let periodList = [];

		periods.forEach(p => {
			if (p.isAvailPeriod) {
				periodList.push(p);
			}
		})

		let formData = new FormData();
		formData.append("available_periods", JSON.stringify(periodList));

		fetch("/" + lang + "/schedule/" + teacherId + "/periods", {
			method : "POST",
			body : formData,
			headers: new Headers({
				"X-CSRF-TOKEN": token
			})
		})
		.then(res => res.json())
		.then(res => {
			window.location.reload();
		});
	}

	const newPeriodPrompt = (langDict, day, period, selectedRoom, selectedUser, callback) => {
		if (availableTypes.length == 1) {
			callback({
				"user_id" : teacherId,
				"day" : day,
				"period" : period,
				"editable" : true,
				"type" : availableTypes[0],
				"title" : langDict.gen["AVAILABLE"],
				"isAvailPeriod" : true,
			});
		}
		else {
			let div = document.createElement("div");

			let form = document.createElement("form");
			form.classList.add("choice-form");
			div.append(form);

			availableTypes.forEach((availableType, i) => {
				let lab1 = document.createElement("label");
				lab1.classList.add("form-control", "form-control-sm", "mb-2", "text-sm");
				let rad1 = document.createElement("input");
				rad1.classList.add("me-2");
				rad1.type = "radio";
				rad1.name = "choice";

				let labelText = langDict.gen[availableType];
				rad1.value = availableType;
				lab1.append(rad1, labelText);

				if (i == 0) {
					rad1.setAttribute("checked", "checked");
				}

				form.append(lab1);
			})

			show(langDict.gen["Day"] + " " + day + ", " + langDict.gen["Period"] + " " + period, div, modalView => {
				modalView.hide();
				let chosenType = document.querySelector(".choice-form").choice.value;

				callback({
					"user_id" : teacherId,
					"day" : day,
					"period" : period,
					"editable" : true,
					"type" : chosenType,
					"title" : availableTypes.length === 1 ? langDict.gen["AVAILABLE"] : langDict.gen[chosenType],
					"isAvailPeriod" : true,
				});
			});
		}
	}

	fetch("/" + lang + "/schedule/" + teacherId + "/periods")
	.then(res => res.json())
	.then(res => {
		let periods = [];
		availableTypes = res["available_types"];

		res["available_periods"].forEach(p => {
			p.isAvailPeriod = true;
			p.title = p.localizedType;
			p.editable = availableTypes.indexOf(p.type) != -1;

			periods.push(p);
		});

		res["course_periods"].forEach(p => {
			p.title = p.course_name + "-" + p.group;
			p.subTitle = p.room;
			p.editable = false;
			periods.push(p);
		});

		ReactDOM.render(
			<React.StrictMode>
				<React.Fragment>
					<CourseSchedule periods={periods} teacherId={teacherId} lang={lang} save={save} newPeriodPrompt={newPeriodPrompt} editable={true} />
				</React.Fragment>
			</React.StrictMode>,
			document.getElementById(nodeId));
	});
}

function CourseSchedule (props) {
	const [lang, updateLang] = useState(null);
	const [periods, updatePeriods] = useState(props.periods);
	const [selectedPeriod, updateSelectedPeriod] = useState(null);
	const [selectedPeriodToEdit, updateSelectedPeriodToEdit] = useState(null);

	useEffect(() => {
		fetch("/js/" + props.lang + "/translation.js")
		.then(res => res.json())
		.then(res => {
			updateLang(res);
		});
	}, [props.lang]);

	const newPeriod = (day, period) => {
		if (props.newPeriodPrompt != null) {
			props.newPeriodPrompt(lang, day, period, null, null, (data => {
				let list = [...periods];
				list.push(data);

				updatePeriods(list);
				updateSelectedPeriodToEdit(null);
			}));
		}
	}

	const removePeriod = () => {
		let list = [...periods];

		list.splice(list.indexOf(selectedPeriodToEdit), 1);

		updatePeriods(list);
		updateSelectedPeriodToEdit(null);
	}

	const selectPeriodToEdit = period => {
		if (period.editable) {
			if (selectedPeriodToEdit === period) {
				updateSelectedPeriodToEdit(null);
			}
			else {
				updateSelectedPeriodToEdit(period);
			}
		}
	}

	const selectPeriod = period => {
		if (period == null || period.editable) {
			if (selectedPeriod === period) {
				updateSelectedPeriod(null);
			}
			else {
				updateSelectedPeriod(period);
			}
		}
	}

	const mouseMoved = (day, period) => {
		let p = selectedPeriod;

		if (p != null) {
			updateSelectedPeriodToEdit(null);
			p.day = day;
			p.period = period;

			let list = [...periods];
			updatePeriods(list);
		}
	}

	const updatePeriod = () => {
		if (props.newPeriodPrompt != null) {
			props.newPeriodPrompt(lang, selectedPeriodToEdit.day, selectedPeriodToEdit.period, selectedPeriodToEdit.room, selectedPeriodToEdit.user_id, (data => {
				let list = [...periods];
				data.id = selectedPeriodToEdit.id;

				list.splice(list.indexOf(selectedPeriodToEdit), 1);
				list.push(data);

				updatePeriods(list);
				updateSelectedPeriodToEdit(null);
			}));
		}
	}

	return 	<div>
				{
					lang != null ?
						<div>
							<table className='table schedule-table'>
								<thead>
									<tr className='text-center'>
										{
											[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => {
												return <th key={"header-col-" + num}>{num === 0 ? lang.gen["Period"] + "\\" + lang.gen["Day"] : num}</th>
											})
										}
									</tr>
								</thead>
								<tbody>
									{
										[1, 2, 3, 4, 5, 6, 7].map(row => {
											return <tr key={"row-" + row}>
												{
													[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => {
														let period = null;

														periods.forEach(p => {
															if (p.day == num && p.period == row) {
																period = p;
															}
														});

														return <td key={"col-" + num} onMouseUp={() => selectPeriod(null)} className={"border text-center " + (period != null ? (selectedPeriod === period || selectedPeriodToEdit === period ? "bg-info text-white" : (period.isAvailPeriod ? (period.editable ? "bg-indigo text-white" : "bg-primary text-white faded") : "bg-secondary text-white")) : "")}>
																	{
																		num === 0 ?
																			row
																		:
																		period != null ?
																			<div className='cursor-pointer' onClick={() => selectPeriodToEdit(period)} onMouseDown={() => selectPeriod(period)} >
																				<span>{ period.title }</span>
																				{
																					period.subTitle != null ?
																						<span className='d-block text-xs text-center'>
																							{period.subTitle}
																						</span>
																					:
																						null
																				}
																			</div>
																		:
																			<div className='cursor-pointer' onMouseEnter={() => mouseMoved(num, row)} onClick={() => newPeriod(num, row)}>
																				<span>-</span>
																			</div>
																	}
																</td>
													})
												}
											</tr>
										})

									}
								</tbody>
							</table>
							{
								props.editable ?
									<div className='mt-3 d-flex justify-content-between'>
										<div>
											{
												selectedPeriodToEdit != null ?
													<button className='btn btn-danger' onClick={removePeriod}>{lang.gen["Delete"]}</button>
												:
													null
											}
										</div>
										<div>
											{
												selectedPeriodToEdit != null ?
													<button className='btn btn-info' onClick={updatePeriod}>{lang.gen["Update"]}</button>
												:
													null
											}
										</div>
										<div>
											<button className='btn btn-primary' onClick={() => props.save(periods)}>{lang.gen["Save"]}</button>
										</div>
									</div>
								:
								null
							}
						</div>
					:
						null
				}
			</div>
}